export const UploadComponentCqs = ({ role, defaultValue, isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<select defaultValue={defaultValue} className="form-select mb-2" name="typefile" id="typefile">
				{
					role === 'ADMIN' || role === 'AGENTE' ?
						<>
							{defaultValue === 'contract' ? <option value="contract">Contratto</option> : null}
							{defaultValue === 'poaCQS' ? <option value="poaCQS">Procura</option> : null}
							{defaultValue === 'doc119' ? <option value="doc119">119</option> : null}
						</>
						: null}
			</select>

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}

export const UploadCustomComponentCqs = ({ isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<label htmlFor="filename">Nome File</label>
			<input type="text" className="form-control mb-2" name="filename" id="filename" />

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}

export const UploadCustomComponentCqsPec = ({ isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<label htmlFor="filename">Nome File</label>
			<input type="text" className="form-control mb-2" name="filename" id="filename" />

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}