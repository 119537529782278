import React, { useState, useEffect } from 'react';

import { usePracticesCqs } from '../../contexts/practices-cqs-context';
import { usePracticesDebts } from '../../contexts/practices-debts-context';
import { usePracticesLoans } from '../../contexts/practices-loans-context';

const SummaryPractices = () => {
	const { getCqsSummary } = usePracticesCqs()
	const { getDebtsSummary } = usePracticesDebts()
	const { getLoansSummary } = usePracticesLoans()

	const [cqs, setCqs] = useState(null);
	const [loans, setLoans] = useState(null);
	const [debts, setDebts] = useState(null);

	useEffect(() => {
		const loadSummaryCqs = async () => {
			const { data } = await getCqsSummary();
			setCqs(data);
		}
		const loadSummaryLoans = async () => {
			const { data } = await getLoansSummary();
			setLoans(data);
		}
		const loadSummaryDebts = async () => {
			const { data } = await getDebtsSummary();
			setDebts(data);
		}

		loadSummaryCqs();
		loadSummaryLoans();
		loadSummaryDebts();
	}, []);

	return (
		<div className="practices-home-page">
			<div className="row">
				{cqs !== null ?
					<div className="col-md-4">
						<h5 className="fw-bold">Pratiche CQS</h5>
						<hr />

						<div style={{ overflow: 'auto', maxHeight: 280 }}>
							<table className="table table-striped">
								<thead>
									<tr>
										<th>Stato Pratica</th>
										<th>N. Pratiche</th>
									</tr>
								</thead>
								<tbody>
									{
										cqs.map((item, index) => {
											return (
												<tr key={index}>
													<td>{item.type}</td>
													<td>{item.count}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
					: null}

				{loans !== null ?
					<div className="col-md-4">
						<h5 className="fw-bold">Pratiche Prestito Personale</h5>
						<hr />
						<div style={{ overflow: 'auto', maxHeight: 280 }}>
							<table className="table table-striped">
								<thead>
									<tr>
										<th>Stato Pratica</th>
										<th>N. Pratiche</th>
									</tr>
								</thead>
								<tbody>
									{
										loans.map((item, index) => {
											return (
												<tr key={index}>
													<td>{item.type}</td>
													<td>{item.count}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
					: null}

				{debts !== null ?
					<div className="col-md-4">
						<h5 className="fw-bold">Pratiche Gestione Debito</h5>
						<hr />
						<div style={{ overflow: 'auto', maxHeight: 280 }}>
							<table className="table table-striped">
								<thead>
									<tr>
										<th>Stato Pratica</th>
										<th>N. Pratiche</th>
									</tr>
								</thead>
								<tbody>
									{
										debts.map((item, index) => {
											return (
												<tr key={index}>
													<td>{item.type}</td>
													<td>{item.count}</td>
												</tr>
											)
										})
									}
								</tbody>
							</table>
						</div>
					</div>
					: null}
			</div>
		</div>
	)
}

export default SummaryPractices;