import React from 'react';

import { useBank } from '../../../contexts/banks-context';
import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormBank from '../../../ui-components/forms-components/bank';

const NewBank = ({ setShowNewBank, setBanks }) => {
	const { newBank, getBanks } = useBank();

	const onSubmit = async (form) => {
		const { error } = await newBank(form);

		if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);

		const { data } = await getBanks();
		setBanks(data.data);
		setShowNewBank(false);
	}

	return (
		<NewPageWrapper className="new-Bank-page new-page">
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuova Banca</h3>
					<button className="close-button" onClick={() => setShowNewBank(false)}>&times;</button>
				</div>

				<hr />

				<FormBank handleSave={onSubmit} />
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default NewBank;