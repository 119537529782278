import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

import FormPracticeCqs from '../../../ui-components/forms-components/practice-cqs';
import { InputTextArea } from '../../../ui-components/forms';

import { usePracticesCqs } from '../../../contexts/practices-cqs-context';
import { useAuth } from '../../../contexts/auth-context';
import { useComments } from '../../../contexts/comments-context';

import { deleteCustomFile, deleteFile, downloadFile, previewCustomFile, uploadCustomFile, uploadFile } from '../../../utils/file';
import { UploadComponentCqs, UploadCustomComponentCqs, UploadCustomComponentCqsPec } from './file-components';


const EditPracticeCqs = (props) => {
	const { id } = props.match.params;
	const { userId, role } = useAuth();
	const { uploadCustomFileCqsPec, downloadCustomFileCqsPec, deleteCustomFileCqsPec, generateFileByType, editPractice, getSinglePractice, downloadFileCqs, uploadFileCqs, uploadCustomFileCqs, deleteFileCqs, downloadCustomFileCqs, deleteCustomFileCqs, logs, resetExpireTimer } = usePracticesCqs();
	const { getComments, newComment } = useComments();

	const [practice, setPractice] = useState(null);
	const [comments, setComments] = useState([]);
	const [commentText, setCommentText] = useState('');
	const isDisable = false;
	const [showUpload, setShowUpload] = useState(false);
	const [defaultUploadValue, setDefaultUploadValue] = useState(null);
	const [showCustomUpload, setShowCustomUpload] = useState(false);
	const [showCustomUploadPec, setShowCustomUploadPec] = useState(false);
	const [textBtn, setTextBtn] = useState("Carica");

	useEffect(() => {
		const getPractice = async () => {
			const { data, error } = await getSinglePractice(id);

			if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);

			setPractice(data.data);

			// load comments
			const comments = await getComments('cqs', id);

			if (comments.error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(comments.error)}`);


			setComments(comments.data);
		}

		getPractice();
	}, [id])

	const onSubmit = async (data) => {
		data['agentId'] = data['agentId'] === undefined ? null : data['agentId'];

		const { error } = await editPractice(id, data);

		if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);

		window.location.reload();
	}

	const handleResetExpire = async (e, date) => {
		e.preventDefault();

		await resetExpireTimer(id, date);
		window.location.reload();
	}

	const handleAddComment = async (e) => {
		e.preventDefault();

		const result = await newComment('cqs', id, { comment: commentText });

		if (result.error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(result.error.response)}`);

		window.location.reload();
	}

	const handleGenerateFile = async (generateFile) => {
		if (generateFile === null) return alert('Seleziona il modello da generare');
		const { data, error } = await generateFileByType(id, 'RECLAMO', generateFile);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: 'application/octet-stream'
		});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename;

		document.body.appendChild(element);

		element.click();
		element.remove();
	}

	return (
		<div className="edit-customer-page px-3 py-3">
			{
				practice ?
					<div className="row">
						<div className="col-md-8">
							<div className="top-page">
								<h3 className="top-page-title">Modifica Pratica</h3>
							</div>

							<hr />

							<FormPracticeCqs handleGenerateFile={handleGenerateFile} handleResetExpire={handleResetExpire} role={role} isDisable={isDisable} practice={practice} handleSave={onSubmit} />
						</div>
						<div className="col-md-4">
							<div className="top-page">
								<h3 className="top-page-title">Documenti Pratica</h3>
							</div>

							<hr />

							{/* todo complete this step for debts and loans */}

							{showUpload ? <UploadComponentCqs role={role} textBtn={textBtn} defaultValue={defaultUploadValue} isDisable={isDisable} handleUpload={(e) => uploadFile(e, id, uploadFileCqs, setTextBtn)} /> : null}
							{showCustomUpload ? <UploadCustomComponentCqs textBtn={textBtn} isDisable={isDisable} handleUpload={(e) => uploadCustomFile(e, id, uploadCustomFileCqs, setTextBtn)} /> : null}
							{showCustomUploadPec ? <UploadCustomComponentCqsPec textBtn={textBtn} isDisable={isDisable} handleUpload={(e) => uploadCustomFile(e, id, uploadCustomFileCqsPec, setTextBtn)} /> : null}

							<table className="table table-striped">
								<thead>
									<tr>
										<th>Tipo Documento</th>
										<th>Stato</th>
										<th>Azioni</th>
									</tr>
								</thead>
								<tbody>
									{
										role === 'ADMIN' || role === 'AGENTE' ?
											<>
												<tr>
													<td>Contratto</td>
													<td>{practice.documents.contract === null ? 'n/a' : 'ok'}</td>
													<td>
														{practice.documents.contract === null ?
															<button onClick={() => { setShowUpload(true); setDefaultUploadValue('contract') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
															:
															<>
																<button onClick={(e) => { e.preventDefault(); downloadFile(id, 'contract', downloadFileCqs) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>&nbsp;
																{!isDisable ? <button onClick={(e) => { e.preventDefault(); deleteFile(id, 'contract', deleteFileCqs) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button> : null}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>Procura</td>
													<td>{practice.documents.poaCQS === null ? 'n/a' : 'ok'}</td>
													<td>
														{practice.documents.poaCQS === null ?
															<button onClick={() => { setShowUpload(true); setDefaultUploadValue('poaCQS') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
															:
															<>
																<button onClick={(e) => { e.preventDefault(); downloadFile(id, 'poaCQS', downloadFileCqs) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>&nbsp;
																{!isDisable ? <button onClick={(e) => { e.preventDefault(); deleteFile(id, 'poaCQS', deleteFileCqs) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button> : null}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>119</td>
													<td>{practice.documents.doc119 === null ? 'n/a' : 'ok'}</td>
													<td>
														{practice.documents.doc119 === null ?
															<button onClick={() => { setShowUpload(true); setDefaultUploadValue('doc119') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
															:
															<>
																<button onClick={(e) => { e.preventDefault(); downloadFile(id, 'doc119', downloadFileCqs) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>&nbsp;
																{!isDisable ? <button onClick={(e) => { e.preventDefault(); deleteFile(id, 'doc119', deleteFileCqs) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button> : null}
															</>
														}
													</td>
												</tr>
											</>
											: null}
								</tbody>
							</table>

							{
								practice.othersDocuments.length > 0 && (role === 'ADMIN') || (role === 'AGENTE' && practice.agentId === userId) ?
									<>
										<div className="top-page mt-4">
											<h3 className="top-page-title">Documenti Personalizzati Pratica</h3>
										</div>

										<hr />

										<table className="table table-striped">
											<thead>
												<tr>
													<th>Nome Documento</th>
													<th>Azioni</th>
												</tr>
											</thead>
											<tbody>
												{
													practice.othersDocuments.map((item, index) => {
														if (typeof item === 'string') {
															return (
																<tr key={index}>
																	<td>{String(item).replace(`${id}-`, ``).replace(`-Custom`, ``)}</td>
																	<td>
																		<button onClick={(e) => { e.preventDefault(); previewCustomFile(id, item, downloadCustomFileCqs, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																		<button onClick={(e) => { e.preventDefault(); deleteCustomFile(id, item, deleteCustomFileCqs) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																	</td>
																</tr>
															)
														}

														if (typeof item === 'object') {
															if (role === 'ADMIN') {
																return (
																	<tr key={index}>
																		<td>{String(item.filename).replace(`${id}-`, ``).replace(`-Custom`, ``)}</td>
																		<td>
																			<button onClick={(e) => { e.preventDefault(); previewCustomFile(id, item.filename, downloadCustomFileCqs, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																			<button onClick={(e) => { e.preventDefault(); deleteCustomFile(id, item.filename, deleteCustomFileCqs) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																		</td>
																	</tr>
																)
															} else {
																if (item.userId == userId) {
																	return (
																		<tr key={index}>
																			<td>{String(item.filename).replace(`${id}-`, ``).replace(`-Custom`, ``)}</td>
																			<td>
																				<button onClick={(e) => { e.preventDefault(); previewCustomFile(id, item.filename, downloadCustomFileCqs, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																				<button onClick={(e) => { e.preventDefault(); deleteCustomFile(id, item.filename, deleteCustomFileCqs) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																			</td>
																		</tr>
																	)
																}
															}
														}

														return null;
													})
												}
											</tbody>
										</table>
									</>
									: null
							}

							{
								role === 'ADMIN' || (role === 'AGENTE' && practice.agentId === userId) ?
									<button className="btn btn-primary mb-3" onClick={() => setShowCustomUpload(!showCustomUpload)}>Aggiungi File Personalizzato</button>
									: null}

							{
								practice.pecDocuments.length > 0 && role === 'ADMIN' ?
									<>
										<div className="top-page mt-4">
											<h3 className="top-page-title">Documenti PEC Pratica</h3>
										</div>

										<hr />

										<table className="table table-striped">
											<thead>
												<tr>
													<th>Nome Documento</th>
													<th>Azioni</th>
												</tr>
											</thead>
											<tbody>
												{
													practice.pecDocuments.map((item, index) => {
														if (typeof item === 'string') {
															return (
																<tr key={index}>
																	<td>{String(item).replace(`${id}-`, ``).replace(`-Pec`, ``)}</td>
																	<td>
																		<button onClick={(e) => { e.preventDefault(); previewCustomFile(id, item, downloadCustomFileCqsPec, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																		<button onClick={(e) => { e.preventDefault(); deleteCustomFile(id, item, deleteCustomFileCqsPec) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																	</td>
																</tr>
															)
														}

														if (typeof item === 'object') {
															if (role === 'ADMIN') {
																return (
																	<tr key={index}>
																		<td>{String(item.filename).replace(`${id}-`, ``).replace(`-Pec`, ``)}</td>
																		<td>
																			<button onClick={(e) => { e.preventDefault(); previewCustomFile(id, item.filename, downloadCustomFileCqsPec, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																			<button onClick={(e) => { e.preventDefault(); deleteCustomFile(id, item.filename, deleteCustomFileCqsPec) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																		</td>
																	</tr>
																)
															} else {
																if (item.userId == userId) {
																	return (
																		<tr key={index}>
																			<td>{String(item.filename).replace(`${id}-`, ``).replace(`-Pec`, ``)}</td>
																			<td>
																				<button onClick={(e) => { e.preventDefault(); previewCustomFile(id, item.filename, downloadCustomFileCqsPec, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																				<button onClick={(e) => { e.preventDefault(); deleteCustomFile(id, item.filename, deleteCustomFileCqsPec) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																			</td>
																		</tr>
																	)
																}
															}
														}

														return null;
													})
												}
											</tbody>
										</table>
									</>
									: null
							}

							{
								role === 'ADMIN' ?
								< button className="btn btn-primary mx-1 mb-3" onClick={() => setShowCustomUploadPec(!showCustomUploadPec)}>Aggiungi File PEC</button>
							: null }

							<div className="comments-area mt-4">
							<div className="top-page">
								<h3 className="top-page-title">Area Commenti</h3>
							</div>

							<hr />

							{
								comments.length > 0 ?
									<>
										<div className="list-comments">
											<ul>
												{
													comments.map((item, index) => {
														return (
															<li className="comment-item" key={index}>
																<p className="comment-author">{item.userdata[0].username} - <span className="comment-date">{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</span></p>
																<p className="comment-text">{item.comment}</p>
															</li>
														)
													})
												}
											</ul>
										</div>

										<hr />
									</>
									: null
							}

							<div className="add-comment">
								<form onSubmit={handleAddComment}>
									<InputTextArea name="comment-text" label="Scrivi un commento:" onChange={setCommentText} />
									<input type="submit" className="btn btn-primary mt-1" value="Commenta" />
								</form>
							</div>
						</div>

						{
							role === 'ADMIN' ?
								<>
									<div className="top-page mt-4">
										<h3 className="top-page-title">Logs</h3>
									</div>

									<hr />

									<div className="wrapper-logs">

										<table className="table table-striped">
											<thead>
												<tr>
													<th>Utente</th>
													<th>Azione</th>
													<th>Data</th>
												</tr>
											</thead>
											<tbody>
												{
													logs.map((item, index) => {
														return (
															<tr key={index}>
																<td>{!!item.userdata[0] ? item.userdata[0].username : "-"}</td>
																<td>{item.description}</td>
																<td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
															</tr>
														)
													})
												}
											</tbody>
										</table>
									</div>

								</>
								: null
						}

					</div>
					</div>
					: null}
		</div >
	)
}

export default EditPracticeCqs;
