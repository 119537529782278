import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useBank } from '../../../contexts/banks-context';
import NewBank from '../new-bank';
import { getPagination } from '../../../utils/functions';

const ListBanks = (props) => {
	const { getBanks, deleteBank } = useBank();
	const [banks, setBanks] = useState(null);
	const [search, setSearch] = useState(null);
	const [filter, setFilter] = useState(null);

	const limit = 50;
	const [offset, setOffset] = useState(0);
	const [pagination, setPagination] = useState(null);

	const [showNewBank, setShowNewBank] = useState(false);

	useEffect(() => {
		const initBanks = async () => {
			const { data } = await getBanks(search, filter === 'null' ? null : filter, limit, offset);
			setBanks(data.data);
			getPagination(data.length, limit, setOffset, setPagination);
		}

		initBanks();
	}, [getBanks, filter, limit, offset, search]);

	const handleDeleteBank = async (id) => {
		const confirmDelete = window.confirm('Sei sicuro di voler eliminare la banca?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteBank(id);

		if (error !==null) {
			return console.log('Impossibile eliminare la banca');
		}

		const { data } = await getBanks(search, filter === 'null' ? null : filter, limit, offset);
		setBanks(data.data);
		getPagination(data.length, limit, setOffset, setPagination);
	}

	const handleSearch = async () => {
		const { data } = await getBanks(search, filter === 'null' ? null : filter, limit, offset);
		setBanks(data.data);
		getPagination(data.length, limit, setOffset, setPagination);
	}

	return (
		<div className="list-Banks-page">
			<div className="top-bar">
				<button className="btn btn-primary" onClick={() => setShowNewBank(!showNewBank)}>Nuova Banca</button>

				{/* <select className="form-select d-inline w-auto mx-2" onChange={(e) => setFilter(e.target.value)}> */}
					{/* <option value="null">Seleziona un filtro</option> */}
				{/* </select> */}

				<div className="wrapper-input-group w-auto d-inline-block mx-2">
					<div className="input-group">
						<input className="form-control" placeholder="Cerca" onChange={(e) => setSearch(e.target.value)} />
						<input className="btn btn-primary" type="submit" value="Cerca" onClick={handleSearch} />
					</div>
				</div>
			</div>

			<hr />

			<nav className="pagination-section">
				<ul className="pagination">
					<li className="page-item">
						<button className="page-link" onClick={() => { if (offset > 0) setOffset(offset - limit) }} aria-label="Previous">
							<span aria-hidden="true">&laquo;</span>
						</button>
					</li>
					{ pagination }
					<li className="page-item">
						<button className="page-link" onClick={() => { setOffset(offset + limit) }} aria-label="Next">
							<span aria-hidden="true">&raquo;</span>
						</button>
					</li>
				</ul>
			</nav>

			<table className="table table-striped">
				<thead className="thead-dark">
					<tr>
						<th></th>
						<th>Nome Banca</th>
						<th>PEC Banca</th>
						<th>Data Creazione</th>
					</tr>
				</thead>
				<tbody>
					{banks !==null ?
						banks.map((item, index) => {
							return (
								<tr key={index}>
									<td>
										<NavLink to={`${props.match.path}/${item._id}`} className="btn btn-secondary"><FontAwesomeIcon icon={faEdit} /></NavLink>
										<button type="button" onClick={() => handleDeleteBank(item._id)} className="mx-2 btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
									</td>
									<td className="text-uppercase">{item.name}</td>
									<td>{item.pec}</td>
									<td className="text-uppercase">{moment(item.created_at).format('DD/MM/YYYY')}</td>
								</tr>
							)
						})
						: null}
				</tbody>
			</table>

			{showNewBank ? <NewBank setShowNewBank={setShowNewBank} setBanks={setBanks} /> : null}
		</div>
	)
}

export default ListBanks;