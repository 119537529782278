import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

import { useUser } from '../../../contexts/user-context';
import NewUser from '../new-user';
import { getPagination } from '../../../utils/functions';

const ListUsers = (props) => {
	const { getUsers, deleteUser } = useUser();
	const [users, setUsers] = useState(null);
	const [search, setSearch] = useState(null);
	const [filter, setFilter] = useState(null);

	const limit = 50;
	const [offset, setOffset] = useState(0);
	const [pagination, setPagination] = useState(null);

	const [showNewUser, setShowNewUser] = useState(false);

	useEffect(() => {
		const initUser = async () => {
			const { data } = await getUsers(search, filter === 'null' ? null : filter, limit, offset);
			setUsers(data.data);
			getPagination(data.length, limit, setOffset, setPagination);
		}

		initUser();
	}, [getUsers, filter, limit, offset, search]);

	const handleDeleteUser = async (id) => {
		const confirmDelete = window.confirm('Sei sicuro di voler eliminare l\'utente?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteUser(id);

		if (error !==null) {
			return console.log('Impossibile eliminare il l\'utente');
		}

		const { data } = await getUsers(search, filter === 'null' ? null : filter, limit, offset);
		setUsers(data.data);
		getPagination(data.length, limit, setOffset, setPagination);
	}

	const handleSearch = async () => {
		const { data } = await getUsers(search, filter === 'null' ? null : filter, limit, offset);
		setUsers(data.data);
		getPagination(data.length, limit, setOffset, setPagination);
	}

	return (
		<div className="list-users-page">
			<div className="top-bar">
				<button className="btn btn-primary" onClick={() => setShowNewUser(!showNewUser)}>Nuovo utente</button>

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setFilter(e.target.value)}>
					<option value="null">Seleziona un filtro</option>
					<option value="ADMIN">ADMIN</option>
					<option value="AGENTE">AGENTE</option>
					<option value="AVVOCATO">AVVOCATO</option>
					<option value="CLIENTE">CLIENTE</option>
				</select>

				<div className="wrapper-input-group w-auto d-inline-block">
					<div className="input-group">
						<input className="form-control" placeholder="Cerca un utente" onChange={(e) => setSearch(e.target.value)} />
						<input className="btn btn-primary" type="submit" value="Cerca" onClick={handleSearch} />
					</div>
				</div>
			</div>

			<hr />

			<nav className="pagination-section">
				<ul className="pagination">
					<li className="page-item">
						<button className="page-link" onClick={() => { if (offset > 0) setOffset(offset - limit) }} aria-label="Previous">
							<span aria-hidden="true">&laquo;</span>
						</button>
					</li>
					{ pagination }
					<li className="page-item">
						<button className="page-link" onClick={() => { setOffset(offset + limit) }} aria-label="Next">
							<span aria-hidden="true">&raquo;</span>
						</button>
					</li>
				</ul>
			</nav>

			<table className="table table-striped">
				<thead className="thead-dark">
					<tr>
						<th></th>
						<th>Nome</th>
						<th>Cognome</th>
						<th>Email</th>
						<th>Username</th>
						<th>Ruolo</th>
					</tr>
				</thead>
				<tbody>
					{users !==null ?
						users.map((item, index) => {
							return (
								<tr key={index}>
									<td>
										<NavLink to={`${props.match.path}/${item._id}`} className="btn btn-secondary"><FontAwesomeIcon icon={faEdit} /></NavLink>
										<button type="button" onClick={() => handleDeleteUser(item._id)} className="mx-2 btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
									</td>
									<td>{item.first_name}</td>
									<td>{item.last_name}</td>
									<td>{item.email}</td>
									<td>{item.username}</td>
									<td>{item.role}</td>
								</tr>
							)
						})
						: null}
				</tbody>
			</table>

			{showNewUser ? <NewUser setShowNewUser={setShowNewUser} setUsers={setUsers} /> : null}
		</div>
	)
}

export default ListUsers;