import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useArchives } from '../../contexts/archives-context';
import { useAuth } from '../../contexts/auth-context';

const UploadCustomComponentCustomer = ({ isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<label htmlFor="filename">Nome File</label>
			<input type="text" className="form-control mb-2" name="filename" id="filename" />

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}

const ArchivesAppMy = (props) => {
	const { getObjects, downloadFile, uploadFile, deleteFile } = useArchives();
	const qsVars = qs.parse(props.location.search)
	const { role } = useAuth();

	const [path, setPath] = useState(qsVars['?path']);

	const [objects, setObjects] = useState(null);
	const [contents, setContents] = useState(null);
	const [showCustomUpload, setShowCustomUpload] = useState(true);
	const [textBtn, setTextBtn] = useState("Carica");

	useEffect(() => {
		const getObjs = async () => {
			const { data, error } = await getObjects(path);
			if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);
			setObjects(data.CommonPrefixes);
			setContents(data.Contents);
		}

		getObjs();
	}, [path]);

	const downloadFileArchive = async (path) => {
		const { data, error } = await downloadFile(path);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.Body.data);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: 'application/pdf'
		});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = String(filename).replace('Altri-File/', '');

		document.body.appendChild(element);

		element.click();
		element.remove();
	}

	const uploadCustomFile = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const filename = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 20971520) {
			setTextBtn("Carica");
			return alert('Il file supera i 20MB.');
		}

		const formData = new FormData();
		formData.append('filename', filename);
		formData.append('file', e.target[1].files[0]);

		const { error } = await uploadFile(formData);
		
		if (error !== null) {
			setTextBtn("Carica");
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		window.location.reload();
	}

	const goBack = async () => {
		const backPath = path.split('/');
		const length = backPath.length;

		let newStr = '';
		for (let i = 0; i < length - 2; i++) {
			newStr += backPath[i] === '' ? '/' : backPath[i];
		}

		if (newStr[newStr.length - 1] !== '/') {
			newStr += '/';
		}

		window.location.href = `/archives?path=${newStr}`;
	}

	const handleRemoveFile = async (file) => {
		const { error } = await deleteFile(file);
		
		if (error !== null) return alert("Impossibile eliminare il file.");

		return window.location.reload();
	}

	return (
		<div className="archive-main">
			<div className="top-bar d-flex align-items-center top-page">
				<h3 className="top-page-title">Altri File</h3>
			</div>

			<hr />

			{showCustomUpload && role !== null && role === 'ADMIN' ? <UploadCustomComponentCustomer textBtn={textBtn} handleUpload={uploadCustomFile} /> : null}
			
			{ path !== '/Altri-File/' ? <button onClick={goBack} className="btn btn-primary mb-2">Indietro</button> : null }
			<table className="table table-striped">
				<thead className="thead-dark">
					<tr>
						<th>Nome File</th>
						<th>Ultima Modifica</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					{
						objects !== null ?
							objects.map((item, index) => {
								return (
									<tr key={index}>
										<td><FontAwesomeIcon icon={faFolder} style={{ marginRight: 10 }} /> <a className="fw-bold" href={`/archives?path=${path + item.Prefix.replace(`archives-files${path}`, '')}`}>{item.Prefix.replace(`archives-files${path}`, '')}</a></td>
										<td>n/a</td>
										<td></td>
									</tr>
								)
							})
							: null
					}

					{
						contents !== null ?
							contents.map((item, index) => {
								if (item.Key.replace(`archives-files${path}`, '') !== '') {
									return (
										<tr key={index}>
											<td><FontAwesomeIcon icon={faFile} style={{ marginRight: 10 }} /> <a className="fw-bold" href="#" onClick={() => downloadFileArchive(`${path.substring(1) + item.Key.replace(`archives-files${path}`, '')}`)}>{item.Key.replace(`archives-files${path}`, '')}</a></td>
											<td>{moment(item.LastModified).format('DD/MM/YYYY hh:mm:ss')}</td>
											{ role !== null && role === 'ADMIN' ?
												<td><a href="#" onClick={() => handleRemoveFile(`${path.substring(1) + item.Key.replace(`archives-files${path}`, '')}`)} className="btn btn-danger"><FontAwesomeIcon icon={ faTrash } /></a></td>
												: <td></td>
											}
										</tr>
									)
								}
							})
							: null
					}
				</tbody>
			</table>
		</div>
	)
}

export default ArchivesAppMy;