import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { InputText, InputEmail } from '../../forms';

const StepForm = styled.section``;

const FormBank = ({ isDisable = false, bank = null, handleSave }) => {
	const [stepOpened, setStepOpened] = useState(0);

	const { register, handleSubmit } = useForm({});

	const onSubmit = async (data) => {
		let obj = data;

		// fix 
		for (var p in obj) {
			if (obj[p] === '') {
				obj[p] = undefined;
			}
		}

		await handleSave(obj);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>

			<div className="alert alert-info">
				<strong>TUTTI I CAMPI SEGUITI DA * SONO OBBLIGATORI</strong>
			</div>

			<StepForm style={{ display: stepOpened === 0 ? 'block' : 'none' }}>
				<div className="row">
					<div className="col-md-6">
						<InputText defaultValue={bank !== null ? bank.name : null} disabled={isDisable} name="name" label="Nome Banca" isRequired={true} register={register} />
					</div>

					<div className="col-md-6">
						<InputEmail defaultValue={bank !== null ? bank.pec : null} disabled={isDisable} name="pec" label="Pec Banca" isRequired={true} register={register} />
					</div>
				</div>
			</StepForm>

			<hr />


			{ stepOpened !== 0 ? <input type="button" className="btn btn-dark" value="Precedente" disabled={stepOpened < 1} onClick={() => setStepOpened(stepOpened - 1)} /> : null}
			{ stepOpened !== 0 ? <input type="button" className="btn btn-primary mx-2" value="Prossimo" onClick={() => setStepOpened(stepOpened + 1)} /> : null}
			{ stepOpened === 0 ? <input type="submit" className={stepOpened === 0 ? 'btn btn-primary mx-2' : 'btn btn-primary'} value="Conferma" /> : null}
		</form>
	)
}

export default FormBank;
