import React, { useEffect, useState } from 'react';

import { InputText, InputEmail, InputDate, InputTextArea } from '../../../../forms';

const FamilySection = ({ dataToSubmit = {}, setDataToSubmit = () => {}, customer = null, isDisable = false }) => {
	const [isSpoused, setIsSpoused] = useState(customer !== null ? customer.familiares.spoused : false);
	const [isUnmarried, setIsUnmarried] = useState(customer !== null ? customer.familiares.unmarried : false);
	const [isWidower, setIsWidower] = useState(customer !== null ? customer.familiares.widower : false);
	const [isCohabiting, setIsCohabiting] = useState(customer !== null ? customer.familiares.cohabiting : false);
	const [isSingle, setIsSingle] = useState(customer !== null ? customer.familiares.single : false);
	const [isDivorced, setIsDivorced] = useState(customer !== null ? customer.familiares.divorced : false);
	const [firstNameConj, setFirstNameConj] = useState(customer !== null ? customer.familiares.first_name : undefined);
	const [lastNameConj, setLastNameConj] = useState(customer !== null ? customer.familiares.last_name : undefined);
	const [dateBirthConj, setDateBirthConj] = useState(customer !== null ? customer.familiares.datebirth : undefined);
	const [cfConj, setCfConj] = useState(customer !== null ? customer.familiares.cf : undefined);
	const [emailConj, setEmailConj] = useState(customer !== null ? customer.familiares.email : undefined);
	const [professionConj, setProfessionConj] = useState(customer !== null ? customer.familiares.conjugatedprofession : undefined);
	const [noteConj, setNoteConj] = useState(customer !== null ? customer.familiares.note : undefined);

	useEffect(() => {
		const data = {
			...dataToSubmit,
			familiares: {
				spoused: isSpoused,
				unmarried: isUnmarried,
				widower: isWidower,
				cohabiting: isCohabiting,
				single: isSingle,
				divorced: isDivorced,
				first_name: firstNameConj,
				last_name: lastNameConj,
				datebirth: dateBirthConj,
				cf: cfConj,
				email: emailConj,
				conjugatedprofession: professionConj,
				note: noteConj
			}
		}
		setDataToSubmit(data);
		
	// eslint-disable-next-line
	}, [isSpoused, isUnmarried, isWidower, isCohabiting, isSingle, isDivorced, firstNameConj, lastNameConj, dateBirthConj, cfConj, emailConj, professionConj, noteConj]);

	return (
		<div className="step-family	">
			<div className="top-page">
				<h3 className="top-page-title">Situazione Familiare</h3>
			</div>

			<hr />

			<label htmlFor="is-conjugated" className="mb-2"><input disabled={isDisable} type="radio" name="family-status" id="is-spoused" defaultChecked={isSpoused} onChange={() => {
				setIsSpoused(!isSpoused)
				setIsUnmarried(false)
				setIsWidower(false)
				setIsCohabiting(false)
				setIsSingle(false)
				setIsDivorced(false)
			}} /> <span className="mx-2"> Coniugato?</span></label>
						&nbsp;<label htmlFor="is-unmarried" className="mb-2"><input disabled={isDisable} type="radio" name="family-status" id="is-unmarried" defaultChecked={isUnmarried} onChange={() => {
				setIsUnmarried(!isUnmarried)
				setIsSpoused(false)
				setIsWidower(false)
				setIsCohabiting(false)
				setIsSingle(false)
				setIsDivorced(false)
			}} /> <span className="mx-2"> Nubile?</span></label>
						&nbsp;<label htmlFor="is-widower" className="mb-2"><input disabled={isDisable} type="radio" name="family-status" id="is-widower" defaultChecked={isWidower} onChange={() => {
				setIsWidower(!isWidower)
				setIsUnmarried(false)
				setIsSpoused(false)
				setIsCohabiting(false)
				setIsSingle(false)
				setIsDivorced(false)
			}} /> <span className="mx-2"> Vedovo/a?</span></label>
						&nbsp;<label htmlFor="is-cohabiting" className="mb-2"><input disabled={isDisable} type="radio" name="family-status" id="is-cohabiting" defaultChecked={isCohabiting} onChange={() => {
				setIsCohabiting(!isCohabiting)
				setIsWidower(false)
				setIsUnmarried(false)
				setIsSpoused(false)
				setIsSingle(false)
				setIsDivorced(false)
			}} /> <span className="mx-2"> Convivente?</span></label>
						&nbsp;<label htmlFor="is-single" className="mb-2"><input disabled={isDisable} type="radio" name="family-status" id="is-single" defaultChecked={isSingle} onChange={() => {
				setIsSingle(!isSingle)
				setIsCohabiting(false)
				setIsWidower(false)
				setIsUnmarried(false)
				setIsSpoused(false)
				setIsDivorced(false)
			}} /> <span className="mx-2"> Separato/a?</span></label>
						&nbsp;<label htmlFor="is-divorced" className="mb-2"><input disabled={isDisable} type="radio" name="family-status" id="is-divorced" defaultChecked={isDivorced} onChange={() => {
				setIsDivorced(!isDivorced)
				setIsSingle(false)
				setIsCohabiting(false)
				setIsWidower(false)
				setIsUnmarried(false)
				setIsSpoused(false)
			}} /> <span className="mx-2"> Divorziato/a?</span></label>

			{
				isSpoused ?
					<>
						<hr />

						<div className="row mb-3">
							<div className="col-md-4">
								<InputText style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.familiares.first_name} name="firstNameConj" label="Nome" onChange={setFirstNameConj} />
							</div>
							<div className="col-md-4">
								<InputText style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.familiares.last_name} name="lastNameConj" label="Cognome" onChange={setLastNameConj} />
							</div>
							<div className="col-md-4">
								<InputDate disabled={isDisable} defaultValue={customer === null ? '' : customer.familiares.datebirth} name="datebirthConj" label="Data di Nascita" onChange={setDateBirthConj} />
							</div>
						</div>

						<div className="row">
							<div className="col-md-4">
								<InputText style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.familiares.cf} name="cfConj" label="Codice Fiscale" onChange={setCfConj} validation={/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/} />
							</div>
							<div className="col-md-4">
								<InputEmail disabled={isDisable} defaultValue={customer === null ? '' : customer.familiares.email} name="emailConj" label="Email" onChange={setEmailConj} />
							</div>
							<div className="col-md-4">
								<InputText style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.familiares.profession} name="professionConj" label="Professione" onChange={setProfessionConj} />
							</div>
						</div>

						<InputTextArea style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.familiares.note} name="noteConj" label="Note" onChange={setNoteConj} />
					</>
					: null
			}
		</div>
	)
}

export default FamilySection;