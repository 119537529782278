import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';

import { useCustomer } from '../../contexts/customer-context';

import './database-app.scss';

const DatabaseAppList = ({ customers = [] }) => {
	return (
		<>
			{
				customers.map((item, index) => {
					return (
						<tr>
							<td><NavLink to={`/database/${item._id}`} key={index}>Altro</NavLink></td>
							<td>{item._id}</td>
							<td>{`${item.first_name} ${item.last_name}`}</td>
							<td>{item.email}</td>
							<td>{item.phone}</td>
						</tr>
					)
				})
			}
		</>
	)
}

const DatabaseApp = () => {
	const { getCustomers } = useCustomer();

	const [customers, setCustomers] = useState(null);

	useEffect(() => {
		const getCustomersData = async () => {
			const { data, error } = await getCustomers(null, null, null, null, null, null, null, 10000, 0);

			if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);

			setCustomers(data.data);
		}

		getCustomersData();
	}, []);

	return (
		<div className="database-section">
			<div className="top-page">
				<h3 className="top-page-title">Database | Lista Clienti</h3>
			</div>

			<hr/>

			<div className="database-list">
				<table className="table table-striped">
					<thead>
						<tr>
							<th>Azioni</th>
							<th>ID</th>
							<th>Nome Completo</th>
							<th>Email</th>
							<th>Telefono</th>
						</tr>
					</thead>
					<tbody>
						{
							customers === null ? <p>Caricamento risorse in corso...</p> : <DatabaseAppList customers={customers} />
						}
					</tbody>
				</table>
			</div>
		</div>
	)
}

export default DatabaseApp;