import React, { useEffect, useState } from 'react';

import { InputSelect, InputNumber, InputText } from '../../../../forms';

const ProfessionSection = ({ dataToSubmit = {}, setDataToSubmit = (	) => {}, customer = null, isDisable = false, professionType = undefined, monthlyIncomeNet = undefined, RDC = undefined, othersIncome = undefined, setProfessionType = undefined, setMonthlyIncomeNet = undefined, setRDC = undefined, setOthersIncome = undefined }) => {
	const [prof, setProf] = useState(customer !== null ? customer.profession.prof : undefined);
	const [employer, setEmployer] = useState(customer !== null ? customer.profession.employer : undefined);
	const [monthlyIncomeGross, setMonthlyIncomeGross] = useState(customer!== null ? customer.profession.monthlyIncomeGross : 0);
	const [yearlyIncome, setYearlyIncome] = useState(customer !== null ? customer.profession.yearlyIncome : 0);
	const [subscribedAlbo, setSubscribedAlbo] = useState(customer !== null ? customer.profession.subscribedAlbo : false);
	const [nameAlbo, setNameAlbo] = useState(customer !== null ? customer.profession.nameAlbo : undefined);

	const typeProfessions = [
		{ label: 'Lavoratore Dipendente', value: 'lavoratore-dipendente' },
		{ label: 'Lavoratore Autonomo', value: 'lavoratore-autonomo' },
		{ label: 'Pensionato', value: 'pensionato' },
		{ label: 'Disoccupato', value: 'disoccupato' },
	]

	useEffect(() => {
		const data = {
			...dataToSubmit,
			profession: {
				type_prof: professionType,
				prof: prof,
				employer: employer,
				monthlyIncomeGross: monthlyIncomeGross,
				monthlyIncomeNet: monthlyIncomeNet,
				yearlyIncome: yearlyIncome,
				subscribedAlbo: subscribedAlbo,
				nameAlbo: nameAlbo,
				RDC: RDC,
				othersIncome: othersIncome
			}
		};
		setDataToSubmit(data);

		// eslint-disable-next-line
	}, [professionType, monthlyIncomeNet, RDC, othersIncome, prof, employer, monthlyIncomeGross, yearlyIncome, subscribedAlbo, nameAlbo]);

	return (
		<div className="step-profession">
			<div className="top-page">
				<h3 className="top-page-title">Situazione Lavorativa</h3>
			</div>

			<hr />

			<div className="row mb-3">
				<div className="col-md-4">
					<InputSelect
						disabled={isDisable}
						defaultValue={customer === null ? '' : customer.profession.type_prof}
						name="type_prof"
						label="Seleziona tipo lavoratore"
						data={typeProfessions}
						onChange={setProfessionType} />
				</div>
			</div>


			{professionType !== undefined ?
				<>
					<hr />

					<div className="row">
						{
							professionType === 'lavoratore-dipendente' ||
								professionType === 'lavoratore-autonomo' ?
								<div className="col-md-3">
									<InputText style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.profession.prof} name="prof" label="Professione" onChange={setProf} />
								</div>
								: null
						}

						{
							professionType === 'lavoratore-dipendente' ?
								<>
									<div className="col-md-3">
										<InputText style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.profession.employer} name="employer" label="Datore di Lavoro" onChange={setEmployer} />
									</div>
									<div className="col-md-3">
										<InputNumber price={true} validation={null} disabled={isDisable} type="number" step="0.01" defaultValue={customer === null ? '' : customer.profession.monthlyIncomeGross} name="monthlyIncomeGross" label="Reddito mensile lordo" onChange={setMonthlyIncomeGross} />
									</div>
									<div className="col-md-3">
										<InputNumber price={true} validation={null} disabled={isDisable} type="number" step="0.01" defaultValue={customer === null ? '' : customer.profession.monthlyIncomeNet} name="monthlyIncomeNet" label="Reddito mensile netto" onChange={setMonthlyIncomeNet} />
									</div>
								</>
								: null
						}

						{
							professionType === 'lavoratore-autonomo' ?
								<>
									<div className="col-md-3">
										<InputNumber price={true} validation={null} disabled={isDisable} type="number" step="0.01" defaultValue={customer === null ? '' : customer.profession.yearlyIncome} name="yearlyIncome" label="Reddito annuo" onChange={setYearlyIncome} />
									</div>
									{subscribedAlbo ?
										<div className="col-md-3">
											<InputText style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.profession.nameAlbo} name="nameAlbo" label="Nome Albo" onChange={setNameAlbo} />
										</div>
										: null}
									<div className="col-md-3">
										<label className="d-block" >&nbsp;</label>
										<label htmlFor="subscribedAlbo" className="mb-2"><input disabled={isDisable} onChange={() => setSubscribedAlbo(!subscribedAlbo)} type="checkbox" defaultChecked={customer === null ? false : customer.profession.subscribedAlbo} name="subscribedAlbo" id="subscribedAlbo" /> <span className="mx-2"> Iscritto albo?</span></label>
									</div>
								</>
								: null
						}

						{
							professionType === 'pensionato' ?
								<>
									<div className="col-md-3">
										<InputNumber price={true} validation={null} disabled={isDisable} type="number" step="0.01" defaultValue={customer === null ? '' : customer.profession.monthlyIncomeGross} name="monthlyIncomeGross" label="Reddito mensile lordo" onChange={setMonthlyIncomeGross} />
									</div>
									<div className="col-md-3">
										<InputNumber price={true} validation={null} disabled={isDisable} type="number" step="0.01" defaultValue={customer === null ? '' : customer.profession.monthlyIncomeNet} name="monthlyIncomeNet" label="Reddito mensile netto" onChange={setMonthlyIncomeNet} />
									</div>
								</>
								: null
						}

						{
							professionType === 'disoccupato' ?
								<>
									<div className="col-md-3">
										<InputText style={{ textTransform: 'uppercase' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.profession.RDC} name="RDC" label="RDC" onChange={setRDC} />
									</div>
									<div className="col-md-3">
										<InputNumber price={true} validation={null} disabled={isDisable} type="number" step="0.01" defaultValue={customer === null ? '' : customer.profession.othersIncome} name="othersIncome" label="Altri Redditi" onChange={setOthersIncome} />
									</div>
								</>
								: null
						}
					</div>
				</>
				: null}
		</div>
	)
}

export default ProfessionSection;