import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import { useAuth } from '../../contexts/auth-context';
import logoEthos from '../../media/logo-ethos-pdf.jpg';

import './index.scss';

const Login = () => {
	const { setToken, login } = useAuth();
	const [error, setError] = useState(null);
	// const [isForgot, setIsForgot] = useState(null);

	const {
		register,
		handleSubmit,
		errors,
		formState: { isValid },
	} = useForm({ mode: 'onChange' });

	const onSubmit = async (data) => {
		try {
			const result = await login({
				username: data.username,
				password: data.password,
			});

			const { token } = result.data;
			setToken(token);

			return window.location.reload();
		} catch (err) {
			const { data, status } = err.response;

			if (status === 429) {
				return setError(data.message)
			}

			return setError(data.error.description);
		}
	}

	return (
		<div className="auth-ethos container-fluid">
			<div className="row">
				<div className="col-md-6 second">
					<div className="copy">
						<h1>Accedi</h1>
						<form onSubmit={handleSubmit(onSubmit)} className={'form-login'}>
							<hr />
							{error && <div>Errore durante il login. {error}</div>}
							<div className="form-field login-form">
								<label className={'oval-circle-red oval--right'} htmlFor="email">Username</label>
								<input name="username" className="form-control" ref={register({ required: true })} />
								{errors.email && <span className={'error-validation'}>Campo obbligatorio</span>}
							</div>
							<div className="form-field mt-3">
								<label className={'oval-circle-red oval--right'} htmlFor="password">Password</label>
								<input type="password" className="form-control" name="password" ref={register({ required: true })} />
								{errors.password && <span className={'error-validation'}>Campo obbligatorio</span>}
							</div>

							<hr />

							<button type="submit" className={`btn btn-block w-100 btn-primary ${!isValid ? 'btn-invalid' : ''}`}>
								Accedi
						</button>
						</form>
					</div>
				</div>
				<div className="col-md-6 first">
					<img src={logoEthos} alt="Logo Ethos" />
				</div>
			</div>
		</div>
	)
}

export default Login;