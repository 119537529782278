import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EditPracticeDebts from '../components/practices-debts/edit-practice-debts';
import ListPracticesDebts from '../components/practices-debts/list-practices-debts';

import { PracticesDebtsProvider } from '../contexts/practices-debts-context';
import { CustomerProvider } from '../contexts/customer-context';
import { BanksProvider } from '../contexts/banks-context';
import { CommentsProvider } from '../contexts/comments-context';
import { UserProvider } from '../contexts/user-context';

const PracticesDebts = ({ match }) => {
	return (
		<div className="practices-page">
			<PracticesDebtsProvider>
				<CustomerProvider>
					<CommentsProvider>
						<BanksProvider>
							<UserProvider>
								<Switch>
									<Route exact path={`${match.path}`} component={ListPracticesDebts} />
									<Route exact path={`${match.path}/:id`} component={EditPracticeDebts} />
								</Switch>
							</UserProvider>
						</BanksProvider>
					</CommentsProvider>
				</CustomerProvider>
			</PracticesDebtsProvider>
		</div>
	)
}

export default PracticesDebts;