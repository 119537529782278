import React from 'react';
import { GoogleLogin } from 'react-google-login';

import { useGoogle } from '../contexts/google-context';
import { PracticesCqsProvider } from '../contexts/practices-cqs-context';
import { PracticesDebtsProvider } from '../contexts/practices-debts-context';
import { PracticesLoansProvider } from '../contexts/practices-loans-context';
import SummaryPractices from '../components/dashboard-sections/summary-practices';

const Home = () => {
	const { authGoogleAccount, checkIfIsAuthed } = useGoogle();

	const handleGoogleLogin = async (res) => {
		if (res.error) {
			return ;
		}

		const data = {
			access_token: res.accessToken,
			id_token: res.tokenId,
			expiry_date: new Date(res.tokenObj.expires_at).toString(),
			token_type: res.tokenObj.token_type,
			scope: res.tokenObj.scope
		};

		console.log(data);

		await authGoogleAccount(data);
		window.location.reload();
	}

	return (
		<div className="home-page">
			<PracticesCqsProvider>
				<PracticesDebtsProvider>
					<PracticesLoansProvider>
						<SummaryPractices />
					</PracticesLoansProvider>
				</PracticesDebtsProvider>
			</PracticesCqsProvider>

			<hr/>

			{ !checkIfIsAuthed() ? 
			<GoogleLogin
					clientId="1062432777203-rd0rr1gus1egrsgnhu8is7e3gtahcceq.apps.googleusercontent.com"
					buttonText="Accedi con il tuo account google"
					onSuccess={handleGoogleLogin}
					onFailure={handleGoogleLogin}
					cookiePolicy={'single_host_origin'}
					scope={"https://www.googleapis.com/auth/calendar"}
				/>
			: <p>Account google collegato</p> }
		</div>
	)
}

export default Home;