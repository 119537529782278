import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import FormPracticeLoans from '../../../ui-components/forms-components/practice-loans';
import { InputTextArea } from '../../../ui-components/forms';
import { usePracticesLoans } from '../../../contexts/practices-loans-context';
import { useAuth } from '../../../contexts/auth-context';
import { useComments } from '../../../contexts/comments-context';

const UploadComponentLoans = ({ role, defaultValue, isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<select defaultValue={defaultValue} className="form-select mb-2" name="typefile" id="typefile">
				{
					role === 'ADMIN' || role === 'AGENTE' ?
						<>
							{defaultValue === 'contract' ? <option value="contract">Contratto</option> : null}
							{defaultValue === 'proxy' ? <option value="proxy">Procura</option> : null}
							{defaultValue === 'doc119' ? <option value="doc119">119</option> : null}
						</>
						: null
				}
				{
					role !== 'AGENTE' ? 
					<>
					  {defaultValue === 'anomalia' ? <option value="anomalia">Anomalia Legale</option> : null}
						{defaultValue === 'pagamento' ? <option value="pagamento">Ricevuta Pagamento</option> : null}
					</>
					: null
				}
			</select>

			{ isDisable ? null : <input type="file" className="form-control mb-2" name="file" id="file" />}
			{ isDisable ? null : <input type="submit" value={textBtn} className="btn btn-primary" />}

			<hr />
		</form>
	)
}

const UploadCustomComponentLoans = ({ isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<label htmlFor="filename">Nome File</label>
			<input type="text" className="form-control mb-2" name="filename" id="filename" />

			{ isDisable ? null : <input type="file" className="form-control mb-2" name="file" id="file" />}
			{ isDisable ? null : <input type="submit" value={textBtn} className="btn btn-primary" />}

			<hr />
		</form>
	)
}

const UploadCustomComponentLoansPec = ({ isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<label htmlFor="filename">Nome File</label>
			<input type="text" className="form-control mb-2" name="filename" id="filename" />

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}


const EditPracticeLoans = (props) => {
	const { id } = props.match.params;
	const { userId, role } = useAuth();
	const { uploadCustomFileLoansPec, downloadCustomFileLoansPec, deleteCustomFileLoansPec, generateFileByType, editPractice, getSinglePractice, downloadFileLoans, uploadFileLoans, uploadCustomFileLoans, deleteFileLoans, downloadCustomFileLoans, deleteCustomFileLoans, resetExpireTimer, logs } = usePracticesLoans();
	const { getComments, newComment } = useComments();

	const [practice, setPractice] = useState(null);
	const [comments, setComments] = useState([]);
	const [commentText, setCommentText] = useState('');
	const [isDisable, setIsDisable] = useState(false);
	const [showUpload, setShowUpload] = useState(false);
	const [defaultUploadValue, setDefaultUploadValue] = useState(null);
	const [showCustomUpload, setShowCustomUpload] = useState(false);
	const [showCustomUploadPec, setShowCustomUploadPec] = useState(false);
	const [textBtn, setTextBtn] = useState("Carica");

	useEffect(() => {
		const getPractice = async () => {
			const { data, error } = await getSinglePractice(id);

			if (error !== null) {
				console.log(error);
			}

			setPractice(data.data);
			setIsDisable(data.data.isDisable);

			// load comments
			const comments = await getComments('loans', id);

			if (comments.error !== null) {
				console.log(comments.error);
			}

			setComments(comments.data);
		}

		getPractice();
	}, [])

	const onSubmit = async (data) => {
		data['agentId'] = data['agentId'] === undefined ? null : data['agentId'];
		const { error } = await editPractice(id, data);

		if (error !== null) {
			console.log(error.response);
			return;
		}

		window.location.reload();
	}

	const uploadFile = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const type = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 20971520) {
			setTextBtn("Carica");
			return alert('Il file supera i 20MB.');
		}

		const formData = new FormData();
		formData.append('file', file);

		const { error } = await uploadFileLoans(id, type, formData);
		if (error !== null) {
			setTextBtn("Carica");
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}
		window.location.reload();
	}

	const uploadCustomFile = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const filename = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 20971520) {
			setTextBtn("Carica");
			return alert('Il file supera i 20MB.');
		}

		const formData = new FormData()
		formData.append('file', file);

		const { error } = await uploadCustomFileLoans(id, filename, formData);
		if (error !== null) {
			setTextBtn("Carica");
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		window.location.reload();
	}

	const downloadFile = async (type) => {
		const { data, error } = await downloadFileLoans(id, type);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.Body.data);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: data.buffer.ContentType
		});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename.replace(`${id}-`, '');;

		document.body.appendChild(element);

		element.click();
		element.remove();
	}

	const deleteFile = async (type) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteFileLoans(id, type);
		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}
		window.location.reload();
	}

	const previewCustomFile = async (type, download = true) => {
		const { data, error } = await downloadCustomFileLoans(id, type);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.Body.data);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: data.buffer.ContentType
		});

		if (download) {
			const url = window.URL.createObjectURL(blob);
			const element = document.createElement('a');

			element.href = url;
			element.download = String(filename).replace(`${id}-`, ``).replace(`-Custom`, ``);

			document.body.appendChild(element);

			element.click();
			element.remove();

			return;
		}

		const url = window.URL.createObjectURL(blob);
		window.open(url);
	}

	const deleteCustomFile = async (type) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteCustomFileLoans(id, type);
		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}
		window.location.reload();
	}

	const uploadCustomFilePec = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const filename = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 20971520) {
			setTextBtn("Carica");
			return alert('Il file supera i 20MB.');
		}

		const formData = new FormData()
		formData.append('file', file);

		const { error } = await uploadCustomFileLoansPec(id, filename, formData);
		if (error !== null) {
			setTextBtn("Carica");
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		window.location.reload();
	}

	const previewCustomFilePec = async (type, download = true) => {
		const { data, error } = await downloadCustomFileLoansPec(id, type);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.Body.data);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: data.buffer.ContentType
		});

		if (download) {
			const url = window.URL.createObjectURL(blob);
			const element = document.createElement('a');

			element.href = url;
			element.download = String(filename).replace(`${id}-`, ``).replace(`-Pec`, ``);

			document.body.appendChild(element);

			element.click();
			element.remove();

			return;
		}

		const url = window.URL.createObjectURL(blob);
		window.open(url);
	}

	const deleteCustomFilePec = async (type) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteCustomFileLoansPec(id, type);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		window.location.reload();
	}

	const handleResetExpire = async (e, date) => {
		e.preventDefault();

		await resetExpireTimer(id, date);
		window.location.reload();
	}

	const handleAddComment = async (e) => {
		e.preventDefault();

		const result = await newComment('loans', id, { comment: commentText });

		if (result.error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(result.error.response)}`);


		window.location.reload();
	}

	const handleGenerateFile = async (generateFile, type, objs) => {
		if (generateFile === null) return alert('Seleziona il modello da generare');
		const { data, error } = await generateFileByType(id, type, generateFile, objs);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: 'application/octet-stream'
		});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename;

		document.body.appendChild(element);

		element.click();
		element.remove();
	}

	return (
		<div className="edit-customer-page px-3 py-3">
			{
				practice ?
					<div className="row">
						<div className="col-md-8">
							<div className="top-page">
								<h3 className="top-page-title">Modifica Pratica</h3>
							</div>

							<hr />

							<FormPracticeLoans handleGenerateFile={handleGenerateFile} handleResetExpire={handleResetExpire} role={role} isDisable={isDisable} practice={practice} handleSave={onSubmit} />
						</div>
						<div className="col-md-4">
							<div className="top-page">
								<h3 className="top-page-title">Documenti Pratica</h3>
							</div>

							<hr />

							{showUpload ? <UploadComponentLoans role={role} textBtn={textBtn} defaultValue={defaultUploadValue} isDisable={isDisable} handleUpload={uploadFile} /> : null}
							{showCustomUpload ? <UploadCustomComponentLoans textBtn={textBtn} isDisable={isDisable} handleUpload={uploadCustomFile} /> : null}
							{showCustomUploadPec ? <UploadCustomComponentLoansPec textBtn={textBtn} isDisable={isDisable} handleUpload={uploadCustomFilePec} /> : null}

							<table className="table table-striped">
								<thead>
									<tr>
										<th>Tipo Documento</th>
										<th>Stato</th>
										<th>Azioni</th>
									</tr>
								</thead>
								<tbody>
									{
										role === 'ADMIN' || role === 'AGENTE' ?
											<>
												<tr>
													<td>Contratto</td>
													<td>{practice.documents.contract === null ? 'n/a' : 'ok'}</td>
													<td>
														{practice.documents.contract === null ?
															<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('contract') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
															:
															<>
																&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('contract') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																{!isDisable ?
																	<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('contract') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
																	: null}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>Procura</td>
													<td>{practice.documents.proxy === null ? 'n/a' : 'ok'}</td>
													<td>
														{practice.documents.proxy === null ?
															<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('proxy') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
															:
															<>
																&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('proxy') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																{!isDisable ?
																	<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('proxy') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
																	: null}
															</>
														}
													</td>
												</tr>
												<tr>
													<td>119</td>
													<td>{practice.documents.doc119 === null ? 'n/a' : 'ok'}</td>
													<td>
														{practice.documents.doc119 === null ?
															<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('doc119') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
															:
															<>
																&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('doc119') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																{!isDisable ?
																	<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('doc119') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
																	: null}
															</>
														}
													</td>
												</tr>
											</>
											: null}
											{
									role !== 'AGENTE' ? 
									<>
									<tr>
										<td>Anomalia Legale</td>
										<td>{practice.documents.anomalia === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.anomalia === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('anomalia') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('anomalia') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('anomalia') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
									<tr>
										<td>Ricevuta Pagamento</td>
										<td>{practice.documents.pagamento === null ? 'n/a' : 'ok'}</td>
										<td>
											{practice.documents.pagamento === null ?
												<button disabled={isDisable} onClick={() => { setShowUpload(true); setDefaultUploadValue('pagamento') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
												:
												<>
													&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('pagamento') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
													{!isDisable ?
														<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('pagamento') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
														: null}
												</>
											}
										</td>
									</tr>
									</>
									: null }
								</tbody>
							</table>

							{
								practice.othersDocuments.length > 0 && ((role === 'ADMIN') || (role === 'AGENTE' && practice.agentId === userId)) ?
									<>
										<div className="top-page mt-4">
											<h3 className="top-page-title">Documenti Personalizzati Pratica</h3>
										</div>

										<hr />

										<table className="table table-striped">
											<thead>
												<tr>
													<th>Nome Documento</th>
													<th>Azioni</th>
												</tr>
											</thead>
											<tbody>
												{
													practice.othersDocuments.map((item, index) => {
														if (typeof item === 'string') {
															return (
																<tr key={index}>
																	<td>{String(item).replace(`${id}-`, ``).replace(`-Custom`, ``)}</td>
																	<td>
																		<button onClick={(e) => { e.preventDefault(); previewCustomFile(item, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																		<button onClick={(e) => { e.preventDefault(); deleteCustomFile(item) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																	</td>
																</tr>
															)
														}

														if (typeof item === 'object') {
															if (role === 'ADMIN') {
																return (
																	<tr key={index}>
																		<td>{String(item.filename).replace(`${id}-`, ``).replace(`-Custom`, ``)}</td>
																		<td>
																			<button onClick={(e) => { e.preventDefault(); previewCustomFile(item.filename, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																			<button onClick={(e) => { e.preventDefault(); deleteCustomFile(item.filename) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																		</td>
																	</tr>
																)
															} else {
																if (item.userId == userId) {
																	return (
																		<tr key={index}>
																			<td>{String(item.filename).replace(`${id}-`, ``).replace(`-Custom`, ``)}</td>
																			<td>
																				<button onClick={(e) => { e.preventDefault(); previewCustomFile(item.filename, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																				<button onClick={(e) => { e.preventDefault(); deleteCustomFile(item.filename) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																			</td>
																		</tr>
																	)
																}
															}
														}

														return null;
													})
												}
											</tbody>
										</table>
									</>
									: null
							}

							{
								role === 'ADMIN' || (role === 'AGENTE' && practice.agentId === userId) ?
								<button className="btn btn-primary mb-3" disabled={isDisable} onClick={() => setShowCustomUpload(!showCustomUpload)}>Aggiungi File Personalizzato</button>
								: null
							}

							{
								practice.pecDocuments.length > 0 && role === 'ADMIN' ?
									<>
										<div className="top-page mt-4">
											<h3 className="top-page-title">Documenti PEC Pratica</h3>
										</div>

										<hr />

										<table className="table table-striped">
											<thead>
												<tr>
													<th>Nome Documento</th>
													<th>Azioni</th>
												</tr>
											</thead>
											<tbody>
												{
													practice.pecDocuments.map((item, index) => {
														if (typeof item === 'string') {
															return (
																<tr key={index}>
																	<td>{String(item).replace(`${id}-`, ``).replace(`-Pec`, ``)}</td>
																	<td>
																		<button onClick={(e) => { e.preventDefault(); previewCustomFilePec(item, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																		<button onClick={(e) => { e.preventDefault(); deleteCustomFilePec(item) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																	</td>
																</tr>
															)
														}

														if (typeof item === 'object') {
															if (role === 'ADMIN') {
																return (
																	<tr key={index}>
																		<td>{String(item.filename).replace(`${id}-`, ``).replace(`-Pec`, ``)}</td>
																		<td>
																			<button onClick={(e) => { e.preventDefault(); previewCustomFilePec(item.filename, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																			<button onClick={(e) => { e.preventDefault(); deleteCustomFilePec(item.filename) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																		</td>
																	</tr>
																)
															} else {
																if (item.userId == userId) {
																	return (
																		<tr key={index}>
																			<td>{String(item.filename).replace(`${id}-`, ``).replace(`-Pec`, ``)}</td>
																			<td>
																				<button onClick={(e) => { e.preventDefault(); previewCustomFilePec(item.filename, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																				<button onClick={(e) => { e.preventDefault(); deleteCustomFilePec(item.filename) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																			</td>
																		</tr>
																	)
																}
															}
														}

														return null;
													})
												}
											</tbody>
										</table>
									</>
									: null
							}

							{
								role === 'ADMIN' ?
								<button className="btn btn-primary mx-1 mb-3" onClick={() => setShowCustomUploadPec(!showCustomUploadPec)}>Aggiungi File PEC</button>
								: null
							}

							<div className="comments-area mt-4">
								<div className="top-page">
									<h3 className="top-page-title">Area Commenti</h3>
								</div>

								<hr />

								{
									comments.length > 0 ?
										<>
											<div className="list-comments">
												<ul>
													{
														comments.map((item, index) => {
															return (
																<li className="comment-item" key={index}>
																	<p className="comment-author">{item.userdata[0].username} - <span className="comment-date">{moment(item.created_at).format('DD/MM/YYYY HH:mm:ss')}</span></p>
																	<p className="comment-text">{item.comment}</p>
																</li>
															)
														})
													}
												</ul>
											</div>

											<hr />
										</>
										: null
								}

								<div className="add-comment">
									<form onSubmit={handleAddComment}>
										<InputTextArea name="comment-text" label="Scrivi un commento:" onChange={setCommentText} />
										<input type="submit" className="btn btn-primary mt-1" value="Commenta" />
									</form>
								</div>
							</div>

							{
								role === 'ADMIN' ?
									<>
										<div className="top-page mt-4">
											<h3 className="top-page-title">Logs</h3>
										</div>

										<hr />

										<div className="wrapper-logs">

											<table className="table table-striped">
												<thead>
													<tr>
														<th>Utente</th>
														<th>Azione</th>
														<th>Data</th>
													</tr>
												</thead>
												<tbody>
													{
														logs.map((item, index) => {
															return (
																<tr key={index}>
																	<td>{item.userdata[0].username}</td>
																	<td>{item.description}</td>
																	<td>{moment(item.created_at).format('DD/MM/YYYY')}</td>
																</tr>
															)
														})
													}
												</tbody>
											</table>
										</div>

									</>
									: null
							}

						</div>
					</div>
					: null}
		</div >
	)
}

export default EditPracticeLoans;