import React, { useEffect, useState } from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../../../custom-components';
import { InputText, InputNumber, InputSelect, InputTextArea } from '../../../../../forms';

const AddProperty = ({ setOpenAddProperty, handleSaveItems }) => {
	const [type, setType] = useState('');
	const [address, setAddress] = useState('');
	const [comune, setComune] = useState('');
	const [city, setCity] = useState('');
	const [mqs, setMqs] = useState(0);
	const [status, setStatus] = useState('');
	const [value, setValue] = useState(0);
	const [ipo, setIpo] = useState(false);
	const [note, setNote] = useState('');

	const handleAddItem = async () => {
		const data = {
			type,
			address,
			comune,
			city,
			mqs,
			status,
			value,
			ipo,
			note
		}

		await handleSaveItems(data);
		setOpenAddProperty(false);
	}

	const typeProperty = [
		{ value: 'terreni', label: 'Terreni' },
		{ value: 'capannoni', label: 'Capannoni' },
		{ value: 'altro', label: 'Altro' }
	];

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo Casa</h3>
					<button className="close-button" onClick={() => setOpenAddProperty(false)}>&times;</button>
				</div>

				<hr />

				<div className="row">
					<div className="col-md-3">
						<InputSelect data={typeProperty} label="Tipologia" name="typeProp" onChange={setType} />
					</div>
					<div className="col-md-3">
						<InputText style={{ textTransform: 'uppercase' }} label="Indirizzo" name="address" onChange={setAddress} />
					</div>
					<div className="col-md-3">
						<InputText style={{ textTransform: 'uppercase' }} label="Comune" name="comune" onChange={setComune} />
					</div>
					<div className="col-md-3">
						<InputText style={{ textTransform: 'uppercase' }} label="Città" name="city" onChange={setCity} />
					</div>
				</div>
				<div className="row">
					<div className="col-md-3">
						<InputNumber validation={null} type="number" defaultValue={mqs} label="Metri Quadri" name="mqs" step="1" onChange={setMqs} />
					</div>
					<div className="col-md-3">
						<InputText style={{ textTransform: 'uppercase' }} label="Stato Immobile" name="status" onChange={setStatus} />
					</div>
					<div className="col-md-3">
						<InputNumber price={true} validation={null} type="number" defaultValue={value} label="Valore Presunto" name="mqs" step="0.01" onChange={setValue} />
					</div>
					<div className="col-md-3">
						<label htmlFor="boh" className="d-block">&nbsp;</label>
						<label htmlFor="ipo"><input type="checkbox" name="ipo" id="ipo" onChange={() => setIpo(!ipo)} /> Ipotetica?</label>
					</div>
				</div>
				<div className="row">
					<div className="col-md-12">
						<InputTextArea style={{ textTransform: 'uppercase' }} name="note" label="Note" onChange={setNote} />
					</div>
				</div>

				<hr />

				<input onClick={handleAddItem} type="button" value="Aggiungi" className="btn btn-primary" />

			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

const Property = ({ disabled = false, propertyItems = [], haveProperty = false, setHaveProperty = () => {}, setPropertyItems = () => {}, handleGetTotalPatrimonial = () => {} }) => {
	const [openAddProperty, setOpenAddProperty] = useState(false);
	const [viewedItems, setViewdItems] = useState(null);

	const handleSaveItems = async (data) => {
		setPropertyItems([...propertyItems, data]);
		handleGetTotalPatrimonial();
	}

	useEffect(() => {
		const handleRemove = (item) => {
			const index = propertyItems.indexOf(item);
			setPropertyItems(items => items.filter((_val, i) => i !== index));
		}

		let data = [];

		propertyItems.map((item, i) => {
			data.push(
				<tr key={i}>
					<td>{item.type}</td>
					<td>{item.address}</td>
					<td>{item.comune}</td>
					<td>{item.city}</td>
					<td>{item.mqs}</td>
					<td>{item.status}</td>
					<td>{item.value}</td>
					<td>{item.note}</td>
					<td>{item.ipo ? 'SI' : 'NO' }</td>
					<td><button disabled={disabled} className="close-button d-block ms-auto" type="button" onClick={() => handleRemove(item)}>Rimuovi</button></td>
				</tr>
			)

			return {}
		})

		setViewdItems(data);
	}, [propertyItems, disabled, setPropertyItems]);

	return (
		<>
			<div className="d-flex flex-columns align-items-center">
				<label htmlFor="Property" className="mb-2"><input disabled={disabled} onChange={() => setHaveProperty(!haveProperty)} type="checkbox" defaultChecked={haveProperty} name="Property" id="Property" /> <span className="mx-2"> Proprietà?</span></label>
				{haveProperty && propertyItems.length < 10 && !disabled ? <button onClick={() => setOpenAddProperty(!openAddProperty)} type="button" className="btn btn-primary ms-auto">Aggiungi Proprietà</button> : null}
			</div>

			{ openAddProperty ? <AddProperty setOpenAddProperty={setOpenAddProperty} handleSaveItems={handleSaveItems} /> : null}

			{ propertyItems.length > 0 ?
				<div className="Property-items">
					<hr />

					<table className="table">
						<thead>
							<tr>
								<th>Tipologia</th>
								<th>Indirizzo</th>
								<th>Comune</th>
								<th>Città</th>
								<th>Metri Quadri</th>
								<th>Stato Immobile</th>
								<th>Valore</th>
								<th>Note</th>
								<th>Ipotetica</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								viewedItems
							}
						</tbody>
					</table>
				</div>
				: null}
		</>
	)
}

export default Property;