import React from 'react'

import { useAuth } from './auth-context';
import { httpGet, httpDelete, httpPost, httpPatch } from '../http';

import { BANKS_ENDPOINT } from '../constants/API_ENDPOINT';

const BanksContext = React.createContext()

function BanksProvider(props) {
	const { jwtToken } = useAuth();

	const getBanks = async (search = null, filter = null, limit = 12, offset = 0) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${BANKS_ENDPOINT}`, jwtToken, {
				filter: filter,
				search: search,
				limit: limit,
				offset: offset
			});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getBanksForPractice = async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${BANKS_ENDPOINT}/practices`, jwtToken, {});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getSingleBank = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${BANKS_ENDPOINT}/${id}`, jwtToken, {});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const newBank = async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${BANKS_ENDPOINT}`, jwtToken, data);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const editBank = async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${BANKS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteBank = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${BANKS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}


	return (
		<BanksContext.Provider
			value={{
				getBanks,
				getSingleBank,
				getBanksForPractice,
				newBank,
				editBank,
				deleteBank
			}}
			{...props}
		/>
	)
}

const useBank = () => React.useContext(BanksContext)

export { BanksProvider, useBank }