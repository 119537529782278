import React, { useState } from 'react'

import { useAuth } from './auth-context';
import { httpPost, httpDelete, httpPatch, httpGet, httpDownload } from '../http'
import { PRACTICES_CQS_ENDPOINT } from '../constants/API_ENDPOINT'

const PracticesCqsContext = React.createContext()

function PracticesCqsProvider(props) {
	const { jwtToken } = useAuth();
	const [logs, setLogs] = useState([]);

	const getPractices = async (search = null, filter = null, filiale = null, user = null, customer = null, limit = 12, offset = 1) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practices = await httpGet(`${PRACTICES_CQS_ENDPOINT}`, jwtToken, {
				filter,
				filiale,
				search,
				limit,
				offset,
				user,
				customer
			});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const generateExcel = async (search = null, filter = null, filiale = null, user = null) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practices = await httpGet(`${PRACTICES_CQS_ENDPOINT}/generate-xlsx`, jwtToken, {
				filter,
				filiale,
				search,
				user
			});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getCqsSummary = async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practices = await httpGet(`${PRACTICES_CQS_ENDPOINT}/summary`, jwtToken, {});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getSinglePractice = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpGet(`${PRACTICES_CQS_ENDPOINT}/${id}`, jwtToken);

			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};

			setLogs(practice.data.logs);
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const newPractice = async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpPost(`${PRACTICES_CQS_ENDPOINT}`, jwtToken, data);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const editPractice = async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpPatch(`${PRACTICES_CQS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deletePractice = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpDelete(`${PRACTICES_CQS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const uploadFileCqs = async (id, type, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${PRACTICES_CQS_ENDPOINT}/upload/${type}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const uploadCustomFileCqs = async (id, filename, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${PRACTICES_CQS_ENDPOINT}/upload-custom/${filename}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	} 

	const downloadFileCqs = async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${PRACTICES_CQS_ENDPOINT}/download/${type}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteFileCqs = async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${PRACTICES_CQS_ENDPOINT}/delete/${type}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const downloadCustomFileCqs = async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${PRACTICES_CQS_ENDPOINT}/download-custom/${filename}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteCustomFileCqs = async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${PRACTICES_CQS_ENDPOINT}/delete-custom/${filename}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const uploadCustomFileCqsPec = async (id, filename, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${PRACTICES_CQS_ENDPOINT}/upload-pec/${filename}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	} 

	const downloadCustomFileCqsPec = async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${PRACTICES_CQS_ENDPOINT}/download-pec/${filename}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteCustomFileCqsPec = async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${PRACTICES_CQS_ENDPOINT}/delete-pec/${filename}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const resetExpireTimer = async (id, date) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpGet(`${PRACTICES_CQS_ENDPOINT}/reset-expire/${id}`, jwtToken, { date });

			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const generateFileByType = async (id, type, path) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${PRACTICES_CQS_ENDPOINT}/generate/${id}/${type}`, jwtToken, { path });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const setPayedAndDatePayed = async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${PRACTICES_CQS_ENDPOINT}/set-payed-agent/${id}`, jwtToken, { ...data });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	return (
		<PracticesCqsContext.Provider
			value={{
				getPractices,
				getSinglePractice,
				newPractice,
				editPractice,
				deletePractice,
				uploadFileCqs,
				downloadFileCqs,
				generateExcel,
				deleteFileCqs,
				uploadCustomFileCqs,
				downloadCustomFileCqs,
				deleteCustomFileCqs,
				uploadCustomFileCqsPec,
				downloadCustomFileCqsPec,
				deleteCustomFileCqsPec,
				resetExpireTimer,
				generateFileByType,
				getCqsSummary,
				setPayedAndDatePayed,
				logs
			}}
			{...props}
		/>
	)
}
const usePracticesCqs = () => React.useContext(PracticesCqsContext)

export { PracticesCqsProvider, usePracticesCqs }