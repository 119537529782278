import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EditCustomer from '../components/customers/edit-customer';
import ListCustomers from '../components/customers/list-customers';

import { UserProvider } from '../contexts/user-context';
import { CustomerProvider } from '../contexts/customer-context';

const Customers = ({ match }) => {
	return (
		<div className="customers-page">
			<CustomerProvider>
				<UserProvider>
					<Switch>
						<Route exact path={`${match.path}`} component={ListCustomers} />
						<Route exact path={`${match.path}/:id`} component={EditCustomer} />
					</Switch>
				</UserProvider>
			</CustomerProvider>
		</div>
	)
}

export default Customers;