import React from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import { usePracticesCqs } from '../../../contexts/practices-cqs-context';
import FormPracticeCqs from '../../../ui-components/forms-components/practice-cqs';

const NewPracticeCqs = ({ setShowNewPracticeCqs, setPractices }) => {
	const { newPractice, getPractices } = usePracticesCqs();

	const onSubmit = async (form) => {
		const { error } = await newPractice(form);

		if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);

		const { data } = await getPractices();
		setPractices(data.data);
		setShowNewPracticeCqs(false);

		window.location.reload();
	}

	return (
		<NewPageWrapper className="new-pratice-page new-page">
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuova Pratica Cessione del Quinto</h3>
					<button className="close-button" onClick={() => setShowNewPracticeCqs(false)}>&times;</button>
				</div>

				<hr />

				<FormPracticeCqs handleSave={onSubmit} />
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default NewPracticeCqs;