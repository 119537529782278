import React, { useState, useEffect } from 'react';
import qs from 'querystring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFolder } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useArchives } from '../../contexts/archives-context';

const ArchivesApp = (props) => {
	const { getObjects, downloadFile } = useArchives();
	const qsVars = qs.parse(props.location.search)

	const [path, setPath] = useState(qsVars['?path']);

	const [objects, setObjects] = useState(null);
	const [contents, setContents] = useState(null);

	useEffect(() => {
		const getObjs = async () => {
			const { data, error } = await getObjects(path);
			if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);
			setObjects(data.CommonPrefixes);
			setContents(data.Contents);
		}

		getObjs();
	}, [path]);

	const downloadFileArchive = async (path) => {
		const { data, error } = await downloadFile(path);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.Body.data);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: 'application/pdf'
		});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename;

		document.body.appendChild(element);

		element.click();
		element.remove();
	}

	const goBack = async () => {
		const backPath = path.split('/');
		const length = backPath.length;

		let newStr = '';
		for (let i = 0; i < length - 2; i++) {
			newStr += backPath[i] === '' ? '/' : backPath[i];
		}

		if (newStr[newStr.length - 1] !== '/') {
			newStr += '/';
		}

		window.location.href = `/archives?path=${newStr}`;
	}

	return (
		<div className="archive-main">
			<div className="top-bar d-flex align-items-center top-page">
				<h3 className="top-page-title">Archivio</h3>
			</div>

			<hr />

			
			{ path !== '/' ? <button onClick={goBack} className="btn btn-primary mb-2">Indietro</button> : null }
			<table className="table table-striped">
				<thead className="thead-dark">
					<tr>
						<th>Nome File</th>
						<th>Ultima Modifica</th>
					</tr>
				</thead>
				<tbody>
					{
						objects !== null ?
							objects.map((item, index) => {
								return (
									<tr key={index}>
										<td><FontAwesomeIcon icon={faFolder} style={{ marginRight: 10 }} /> <a className="fw-bold" href={`/archives?path=${path + item.Prefix.replace(`archives-files${path}`, '')}`}>{item.Prefix.replace(`archives-files${path}`, '')}</a></td>
										<td>n/a</td>
									</tr>
								)
							})
							: null
					}

					{
						contents !== null ?
							contents.map((item, index) => {
								if (item.Key.replace(`archives-files${path}`, '') !== '') {
									return (
										<tr key={index}>
											<td><FontAwesomeIcon icon={faFile} style={{ marginRight: 10 }} /> <a className="fw-bold" href="#" onClick={() => downloadFileArchive(`${path.substring(1) + item.Key.replace(`archives-files${path}`, '')}`)}>{item.Key.replace(`archives-files${path}`, '')}</a></td>
											<td>{moment(item.LastModified).format('DD/MM/YYYY hh:mm:ss')}</td>
										</tr>
									)
								}
							})
							: null
					}
				</tbody>
			</table>
		</div>
	)
}

export default ArchivesApp;