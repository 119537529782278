import React from 'react';

import { useAuth } from './auth-context';
import { httpPost, httpGet, httpDelete } from '../http'
import { TICKETS_ENDPOINT } from '../constants/API_ENDPOINT'

const TicketsContext = React.createContext();

function TicketsProvider(props) {
	const { jwtToken } = useAuth();

	const getTickets = async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${TICKETS_ENDPOINT}/list-tickets`, jwtToken, {});
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null
			}
		}

		console.log(obj);

		return obj;
	}

	const getSingleTicket = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${TICKETS_ENDPOINT}/${id}`, jwtToken, {});
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null
			}
		}

		console.log(obj);

		return obj;
	}

	const createTicket = async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${TICKETS_ENDPOINT}/create-ticket`, jwtToken, data);
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null
			}
		}

		return obj;
	}

	const listCommentsByTicket = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${TICKETS_ENDPOINT}/list-comments/${id}`, jwtToken, {});
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null
			}
		}

		return obj;
	}

	const addCommentByTicket = async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${TICKETS_ENDPOINT}/create-comment/${id}`, jwtToken, data);
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null
			}
		}

		return obj;
	}

	const deleteTicket = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${TICKETS_ENDPOINT}`, jwtToken, id);
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null
			}
		}

		return obj;
	}

	const getAllPractices = async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${TICKETS_ENDPOINT}/all-practices`, jwtToken, {});
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null
			}
		}

		return obj;
	}

	const getSinglePractice = async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${TICKETS_ENDPOINT}/single-practice/${id}/${type}`, jwtToken, {});
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null
			}
		}

		return obj;
	}

	return (
		<TicketsContext.Provider
			value={{
				getTickets,
				createTicket,
				listCommentsByTicket,
				addCommentByTicket,
				deleteTicket,
				getAllPractices,
				getSinglePractice,
				getSingleTicket
			}}
			{...props} />
	)
}

const useTickets = () => React.useContext(TicketsContext)

export { TicketsProvider, useTickets }
