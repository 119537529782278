import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EditPracticeLoans from '../components/practices-loans/edit-practice-loans';
import ListPracticesLoans from '../components/practices-loans/list-practices-loans';

import { PracticesLoansProvider } from '../contexts/practices-loans-context';
import { CustomerProvider } from '../contexts/customer-context';
import { BanksProvider } from '../contexts/banks-context';
import { CommentsProvider } from '../contexts/comments-context';
import { UserProvider } from '../contexts/user-context';

const PracticesLoans = ({ match }) => {
	return (
		<div className="practices-page">
			<PracticesLoansProvider>
				<CustomerProvider>
					<CommentsProvider>
						<BanksProvider>
							<UserProvider>
								<Switch>
									<Route exact path={`${match.path}`} component={ListPracticesLoans} />
									<Route exact path={`${match.path}/:id`} component={EditPracticeLoans} />
								</Switch>
							</UserProvider>
						</BanksProvider>
					</CommentsProvider>
				</CustomerProvider>
			</PracticesLoansProvider>
		</div>
	)
}

export default PracticesLoans;