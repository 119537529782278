import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { InputText, InputNumber, InputEmail, InputTextArea, InputDate, InputSelect } from '../../forms';

// sections
import OthersIncomeYearly from './steps/others-income-yearly';
import PatrimonialSection from './steps/patrimonial-section';
import IncomesSection from './steps/incomes-section';
import FinancialSection from './steps/financial-section';
import ProfessionSection from './steps/profession-section';
import FamilySection from './steps/family-section';

const StepForm = styled.section``;

const FormCustomer = ({ isDisable = false, customer = null, handleSave }) => {
	const [stepOpened, setStepOpened] = useState(0);

	const [isContractual, setIsContractual] = useState(customer !== null ? customer.isContractual : false);
	const [professionType, setProfessionType] = useState(customer !== null ? customer.profession.type_prof : undefined);
	const [monthlyIncomeNet, setMonthlyIncomeNet] = useState(customer !== null ? customer.profession.monthlyIncomeNet : 0.00);
	const [RDC, setRDC] = useState(customer !== null ? customer.profession.RDC : undefined);
	const [othersIncome, setOthersIncome] = useState(customer !== null ? customer.profession.othersIncome : 0.00);

	const [dataToSubmit, setDataToSubmit] = useState(
		customer === null ?
			{
				"profession": { "type_prof": "", "prof": "", "employer": "", "monthlyIncomeGross": 0, "monthlyIncomeNet": 0, "yearlyIncome": 0, "subscribedAlbo": false, "nameAlbo": null, "RDC": null, "othersIncome": 0 },
				"familiares": { "spoused": false, "unmarried": false, "widower": true, "cohabiting": false, "single": false, "divorced": false, "first_name": "", "last_name": "", "datebirth": "", "cf": "", "email": "", "conjugatedprofession": "", "note": "" },
				"cqs": { "haveCQS": false, "items": [] },
				"loands": { "haveLoands": false, "items": [] },
				"carte": { "haveCarte": false, "items": [] },
				"delega": { "haveDelega": false, "items": [] },
				"injuctionDecree": false,
				"injuctionDecreeNote": "",
				"mutui": { "haveMutui": false, "items": [] },
				"taxCollection": { "haveTaxCollection": false, "items": [] },
				"othersIncomeMonthly": { "haveOthers": false, "rentals": 0, "foreclosure": 0, "foreclosureSpoused": 0 },
				"incomes": { "incomes": false, "incomesRent": 0, "incomesPension": 0, "incomesOthers": 0, "incomesNote": "" },
				"house": { "haveHouse": false, "items": [] },
				"property": { "haveProperty": false, "items": [] },
				"stock": { "haveStock": false, "items": [] },
				"society": { "haveSociety": false, "societyRole": null, "societyName": null, "societyPiva": null, "societyPerc": 0 },
				"notesPatri": null,
				"monthlyIncome": { "haveMonthlyIncome": false, "items": [] },
				"thirdForeclosureBeneficiary": "",
				"thirdForeclosureImport": 0,
				"spouseForeclosureBeneficiary": "",
				"spouseForeclosureImport": 0,
				"monthlySavingsIntermediary": "",
				"monthlySavingsImport": 0,
				"othersYearlyIncome": 0,
				"othersYearlyIncomeNote": ""
			}
			:
			{
				profession: customer.profession,
				familiares: customer.familiares,
				cqs: customer.cqs,
				loands: customer.loands,
				carte: customer.carte,
				delega: customer.delega,
				injuctionDecree: customer.injuctionDecree,
				injuctionDecreeNote: customer.injuctionDecreeNote,
				mutui: customer.mutui,
				taxCollection: customer.taxCollection,
				othersIncomeMonthly: customer.othersIncomeMonthly,
				incomes: customer.incomes,
				house: customer.house,
				property: customer.property,
				stock: customer.stock,
				society: customer.society,
				notesPatri: customer.notesPatri,
				monthlyIncome: customer.monthlyIncome,
				thirdForeclosureBeneficiary: customer.thirdForeclosureBeneficiary,
				thirdForeclosureImport: customer.thirdForeclosureImport,
				spouseForeclosureBeneficiary: customer.spouseForeclosureBeneficiary,
				spouseForeclosureImport: customer.spouseForeclosureImport,
				monthlySavingsIntermediary: customer.monthlySavingsIntermediary,
				monthlySavingsImport: customer.monthlySavingsImport,
				othersYearlyIncome: customer.othersYearlyIncome,
				othersYearlyIncomeNote: customer.othersYearlyIncomeNote
			}
	);

	const { register, handleSubmit } = useForm({});

	const onSubmit = async (data) => {
		let obj = data;

		// fix 
		for (var p in obj) {
			if (obj[p] === '') {
				obj[p] = undefined;
			}
		}

		if (isContractual) {
			obj = {
				...obj,
				...dataToSubmit
			}
		}

		await handleSave(obj);
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>

			<div className="alert alert-info">
				<strong>TUTTI I CAMPI SEGUITI DA * SONO OBBLIGATORI</strong>
			</div>

			<hr/>

			{ isContractual ? <input type="button" className="btn btn-dark" value="Precedente" disabled={stepOpened < 1} onClick={() => setStepOpened(stepOpened - 1)} /> : null}
			{ stepOpened !== 6 && isContractual ? <input type="button" className="btn btn-primary mx-2" value="Prossimo" onClick={() => setStepOpened(stepOpened + 1)} /> : null}
			<input type="submit" className={stepOpened === 6 ? 'btn btn-primary mx-2' : 'btn btn-primary'} value="Conferma" />

			<hr/>

			<StepForm style={{ display: stepOpened === 0 ? 'block' : 'none' }}>
				<div className="top-page">
					<h3 className="top-page-title">Anagrafiche cliente</h3>
				</div>

				<hr />

				<label htmlFor="isContractual" className="mb-2"><input disabled={isDisable} type="checkbox" name="isContractual" id="isContractual" ref={register} defaultChecked={isContractual} onChange={() => setIsContractual(!isContractual)} /> <span className="mx-2"> Cliente Contrattualizzato?</span></label>

				<div className="row mb-3">
					<div className="col-md-6">
						<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.last_name} name="last_name" label="Cognome" register={register} isRequired={false} />
					</div>
					<div className="col-md-6">
						<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.first_name} name="first_name" label="Nome" register={register} isRequired={false} />
					</div>
				</div>

				<div className="row mb-3">
					<div className="col-md-6">
						<InputEmail disabled={isDisable} defaultValue={customer === null ? '' : customer.email} name="email" label="Email" register={register} isRequired={false} />
					</div>
					<div className="col-md-6">
						<InputNumber style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.phone} name="phone" validation={/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s./0-9]*$/} label="Numero di Telefono" register={register} isRequired={false} />
					</div>
				</div>

				{isContractual ?
					<>
						<div className="row mb-3">
							<div className="col-md-6">
								<InputDate disabled={isDisable} defaultValue={customer === null ? '' : customer.datebirth} name="datebirth" label="Data di Nascita" register={register} />
							</div>
							<div className="col-md-6">
								<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.citybirth} name="citybirth" label="Luogo di Nascita" register={register} />
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-md-4">
								<InputEmail disabled={isDisable} defaultValue={customer === null ? '' : customer.pec} name="pec" label="PEC" register={register} />
							</div>
							<div className="col-md-4">
								<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.cf} name="cf" label="Codice Fiscale" register={register} validation={/^[A-Za-z]{6}[0-9]{2}[A-Za-z]{1}[0-9]{2}[A-Za-z]{1}[0-9]{3}[A-Za-z]{1}$/} />
							</div>
							<div className="col-md-4">
								<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.piva} name="piva" validation={/^[0-9]{11}$/} label="Partita IVA" register={register} />
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-md-6">
								<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.address} name="address" label="Indirizzo" register={register} />
							</div>
							<div className="col-md-6">
								<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.postcode} name="postcode" label="CAP" register={register} />
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-md-6">
								<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.city} name="city" label="Città" register={register} />
							</div>
							<div className="col-md-6">
								<InputText style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.prov} name="prov" label="Provincia" register={register} />
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-md-4">
								<InputSelect disabled={isDisable} defaultValue={customer === null ? '' : customer.sex} label="Sesso" name="sex" register={register} data={[
									{ value: 'UOMO', label: 'UOMO' },
									{ value: 'DONNA', label: 'DONNA' }
								]} />
							</div>
							<div className="col-md-4">
								<InputSelect disabled={isDisable} defaultValue={customer === null ? '' : customer.type} label="Stato Civile" name="type" register={register} data={[
									{ value: 'PERSONA FISICA', label: 'PERSONA FISICA' },
									{ value: 'PERSONA GIURIDICA', label: 'PERSONA GIURIDICA' },
								]} />
							</div>
							<div className="col-md-4">
								<InputSelect disabled={isDisable} defaultValue={customer === null ? '' : customer.titolodistudio} label="Titolo di Studio" name="titolodistudio" register={register} data={[
									{ value: 'DIPLOMA SCUOLA MEDIA', label: 'DIPLOMA SCUOLA MEDIA' },
									{ value: 'DIPLOMA MATURITÀ', label: 'DIPLOMA MATURITÀ' },
									{ value: 'LAUREA TRIENNALE', label: 'LAUREA TRIENNALE' },
									{ value: 'LAUREA MAGISTRALE', label: 'LAUREA MAGISTRALE' }
								]} />
							</div>
						</div>

						<div className="row mb-3">
							<div className="col-md-4">
								<InputNumber defaultValue={customer === null ? 0 : customer.age} name="age" label="Età" register={register} type="number" step="1" validation={null} />
							</div>
						</div>

						<InputTextArea style={{ textTransform: 'uppercase ' }} disabled={isDisable} defaultValue={customer === null ? '' : customer.note} name="note" label="Note" register={register} />
					</>
					: null}
			</StepForm>

			{isContractual ?
				<>
					<StepForm style={{ display: stepOpened === 1 ? 'block' : 'none' }}>
						<FamilySection dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
					</StepForm>

					<StepForm style={{ display: stepOpened === 2 ? 'block' : 'none' }}>
						<ProfessionSection
							dataToSubmit={dataToSubmit}
							setDataToSubmit={setDataToSubmit}
							customer={customer}
							isDisable={isDisable}
							professionType={professionType}
							monthlyIncomeNet={monthlyIncomeNet}
							RDC={RDC}
							othersIncome={othersIncome}
							setProfessionType={setProfessionType}
							setMonthlyIncomeNet={setMonthlyIncomeNet}
							setRDC={setRDC}
							setOthersIncome={setOthersIncome} />
					</StepForm>

					<StepForm style={{ display: stepOpened === 3 ? 'block' : 'none' }}>
						<FinancialSection dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
					</StepForm>

					<StepForm style={{ display: stepOpened === 4 ? 'block' : 'none' }}>
						<IncomesSection
							dataToSubmit={dataToSubmit}
							setDataToSubmit={setDataToSubmit}
							professionType={professionType}
							monthlyIncomeNet={monthlyIncomeNet}
							RDC={RDC}
							othersIncome={othersIncome}
							customer={customer}
							isDisable={isDisable} />
					</StepForm>

					<StepForm style={{ display: stepOpened === 5 ? 'block' : 'none' }}>
						<PatrimonialSection dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
					</StepForm>

					<StepForm style={{ display: stepOpened === 6 ? 'block' : 'none' }}>
						<OthersIncomeYearly dataToSubmit={dataToSubmit} setDataToSubmit={setDataToSubmit} customer={customer} isDisable={isDisable} />
					</StepForm>
				</>
				: null}
		</form>
	)
}

export default FormCustomer;
