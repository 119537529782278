import React from 'react'

import { useAuth } from './auth-context';
import { httpDownload, httpGet, httpUpload, httpPost } from '../http';

import { ARCHIVES_ENDPOINT } from '../constants/API_ENDPOINT';

const ArchivesContext = React.createContext()

function ArchivesProvider(props) {
	const { jwtToken } = useAuth();

	const getObjects = async (path) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${ARCHIVES_ENDPOINT}/list`, jwtToken, { path });

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const uploadFile = async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpUpload(`${ARCHIVES_ENDPOINT}/upload`, jwtToken, data);

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const downloadFile = async (path) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${ARCHIVES_ENDPOINT}/download`, jwtToken, { path });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteFile = async (path) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${ARCHIVES_ENDPOINT}/delete`, jwtToken, {
				path
			});
			
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}


	return (
		<ArchivesContext.Provider
			value={{
				getObjects,
				uploadFile,
				downloadFile,
				deleteFile
			}}
			{...props}
		/>
	)
}

const useArchives = () => React.useContext(ArchivesContext)

export { ArchivesProvider, useArchives }