import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import moment from 'moment';

import { InputText, InputSelect, InputEmail, InputTextArea, InputDate, InputNumber } from '../../forms';
import { useBank } from '../../../contexts/banks-context';
import { useCustomer } from '../../../contexts/customer-context';
import { useUser } from '../../../contexts/user-context';
import { useAuth } from '../../../contexts/auth-context';

import FormReclamo from './generations/reclamo';
import AbfDone from './generations/abf-done';
import AbfRequest from './generations/abf-request';

const StepForm = styled.section``;

const FormPracticeLoans = ({ role = undefined, isDisable = false, practice = null, handleSave, handleResetExpire, handleGenerateFile = () => { } }) => {
	const { getCustomersForPractice } = useCustomer();
	const authData = useAuth();
	const { getUsersPractice, getSingleUser } = useUser();
	const { getBanksForPractice } = useBank();
	const [stepOpened, setStepOpened] = useState(0);
	const [customers, setCustomers] = useState([]);
	const [banks, setBanks] = useState([]);
	const [agents, setAgents] = useState(null);
	const [generateFile, setGenerateFile] = useState(null);
	const agent = practice !== null ? practice.agentId : null;
	const [discount, setDiscount] = useState(practice !== null ? practice.sconto : 0);
	const [importRec, setImportRec] = useState(practice !== null ? practice.importoRecuperato : 0);
	const [fattImportText, setFattImportText] = useState(0);
	const [finalImportText, setFinalImportText] = useState(0);
	const [status, setStatus] = useState(practice !== null ? practice.status : null);
	const [compAgent, setCompAgent] = useState(0);
	const [datePayedEnd, setDatePayedEnd] = useState(practice !== null ? practice.datePayedEnd : null)
	
	const { register, handleSubmit } = useForm({});

	useEffect(() => {
		const getCustomersPractice = async () => {
			const { data, error } = await getCustomersForPractice();

			if (error !== null) return alert('Error to load customers');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					value: item._id,
					label: `${item.first_name} ${item.last_name}`.toUpperCase()
				}
			});

			setCustomers(dataToObj);
		}

		const getBanks = async () => {
			const { data, error } = await getBanksForPractice();

			if (error !== null) return alert('Error to load banks');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					pec: item.pec,
					value: item.name,
					label: item.name
				}
			});

			setBanks(dataToObj);
		}

		const initUser = async () => {
			const { data } = await getUsersPractice('AGENTE');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					value: item._id,
					label: item.username
				}
			});

			setAgents(dataToObj);
		}

		const getAgent = async () => {
			if (practice.agentId !== null) {
				const { data } = await getSingleUser(practice.agentId);
				if (data !== null) {
					setCompAgent(data.comp);
				}else {
					setCompAgent(0);
				}
			}
		}

		if (practice !== null) {
			getAgent();
		}

		initUser();
		getCustomersPractice();
		getBanks();
	}, [getCustomersForPractice, getSingleUser, getBanksForPractice, getUsersPractice, practice])

	useEffect(() => {
		if (practice !== null) {
			const getAgentComp = async (id) => {
				const { data } = await getSingleUser(id);
				if (data !== null) {
					setCompAgent(data.comp);
				}else {
					setCompAgent(0);
				}
			};

			if (practice.agentId !== null) {
				getAgentComp(practice.agentId);
			}

			const fattImport = parseFloat(importRec * 0.35 * 1.22).toFixed(2);
			const finalImport = parseFloat(fattImport - discount).toFixed(2);

			setFattImportText(fattImport);
			setFinalImportText(finalImport);
		}
	}, [discount, importRec, agent, getSingleUser, practice]);

	const onSubmit = async (data) => {
		let obj = data;

		// fix 
		for (var p in obj) {
			if (obj[p] === '') {
				obj[p] = undefined;
			}
		}

		await handleSave(obj);
	}

	const selectStatus = [
		{ value: 'BOZZA', label: 'BOZZA' },
		{ value: '119', label: '119' },
		{ value: 'IN CORSO', label: 'IN CORSO' },
		{ value: 'DA ESAMINARE', label: 'DA ESAMINARE' },
		{ value: 'RECLAMO', label: 'RECLAMO' },
		{ value: 'PREANALISI', label: 'PREANALISI' },
		{ value: 'GIUDIZIALE', label: 'GIUDIZIALE' },
		{ value: 'SOLLECITO', label: 'SOLLECITO' },
		{ value: 'KO', label: 'KO' },
		{ value: 'RECUPERATA', label: 'RECUPERATA' },
		{ value: 'ACCETTAZIONE', label: 'ACCETTAZIONE' },
		{ value: 'CONTRODEDUZIONE', label: 'CONTRODEDUZIONE' },
		{ value: 'ABF', label: 'ABF' }
	]

	const handleOnChangeBank = async (value) => {
		const pec = banks.filter(item => item.label === value)[0].pec;
		document.getElementById('pecBank').value = pec;
	}

	const reclamo = [{ value: 'Prestito-Personale/Reclamo-pp-base.docx', label: 'RECLAMO' }];
	const abf = [{ value: 'Prestito-Personale/ABF-Fatto.docx', label: 'ABF Fatto' }, { value: 'Prestito-Personale/ABF-Richieste.docx', label: 'ABF Richieste' }]
	const [generableFiles, setGenerableFiles] = useState(practice === null ? null : practice.status === 'RECLAMO' ? reclamo : practice.status === 'ABF' ? abf : null)
	const [showForm, setShowForm] = useState(null);

	const onChangeStatus = async (val) => {
		setStatus(val);
		if (val === 'RECLAMO') {
			setGenerableFiles(reclamo);
		}
		else if (val === 'ABF') {
			setGenerableFiles(abf);
		}
		else {
			setGenerableFiles(null);
		}
	}

	const onChangeGenerateFile = async (val) => {
		setGenerateFile(val);

		switch (val) {
			case 'Prestito-Personale/Reclamo-pp-base.docx':
				setShowForm(0)
				break;
			case 'Prestito-Personale/ABF-Fatto.docx':
				setShowForm(1)
				break;
			case 'Prestito-Personale/ABF-Richieste.docx':
				setShowForm(2)
				break;
			default:
				setShowForm(null)
				break;
		}
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>

			<div className="alert alert-info">
				<strong>TUTTI I CAMPI SEGUITI DA * SONO OBBLIGATORI</strong>
			</div>

			<StepForm style={{ display: stepOpened === 0 ? 'block' : 'none' }}>
				<div className="row">
					<div className="col-md-4">
						{customers.length > 0 && !(role === 'AVVOCATO' && practice.judicial) ?
							<InputSelect defaultValue={practice !== null ? practice.clientId : null} name="clientId" data={practice !== null ? [ ...customers.filter(item => item.value === practice.clientId) ] : customers} label="Cliente" isRequired={true} register={register} />
							: null}
						{role === 'AVVOCATO' && practice.judicial ?
							<InputSelect defaultValue={practice !== null ? practice.clientId : null} disabled={isDisable} name="clientId" data={[
								{ value: practice.clientId, label: `${practice.customerdata[0].first_name} ${practice.customerdata[0].last_name}` }
							]} label="Cliente" register={register} />
							: null}
					</div>

					<div className="col-md-4">
						<InputText defaultValue={practice !== null ? practice.nContract : null} disabled={isDisable} name="nContract" label="N. Contratto" isRequired={true} register={register} />
					</div>

					<div className="col-md-4">
						{banks.length > 0 ?
							<InputSelect defaultValue={practice !== null ? practice.bank : null} disabled={isDisable} name="bank" data={banks} label="Finanziaria/Banca" isRequired={true} onChange={handleOnChangeBank} register={register} />
							: null}
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputText defaultValue={practice !== null ? practice.branch : null} disabled={isDisable} name="branch" label="Filiale" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateStart : null} name="dateStart" label="Data inizio Contratto" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateEnd : null} name="dateEnd" label="Data fine Contratto" isRequired={false} register={register} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateClose : null} name="dateClose" label="Data chiusura Contratto" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputEmail defaultValue={practice !== null ? practice.pecBank : null} disabled={isDisable} name="pecBank" label="PEC Banca" register={register} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} defaultValue={practice !== null ? practice.importLoan : null} disabled={isDisable} name="importLoan" label="Importo Prestito" register={register} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="1" isRequired={false} defaultValue={practice !== null ? practice.nInstallments : null} disabled={isDisable} name="nInstallments" label="Nr. Rate" register={register} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} defaultValue={practice !== null ? practice.singleInstallmentImport : null} disabled={isDisable} name="singleInstallmentImport" label="Importo Singola Rata" register={register} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} defaultValue={practice !== null ? practice.tan : null} disabled={isDisable} name="tan" label="TAN" register={register} />
					</div>

				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} defaultValue={practice !== null ? practice.taeg : null} disabled={isDisable} name="taeg" label="TAEG" register={register} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} defaultValue={practice !== null ? practice.teg : null} disabled={isDisable} name="teg" label="TEG" register={register} />
					</div>

					{agents !== null && (role === 'ADMIN' || role === 'AGENTE') ?
						<div className="col-md-4">
							<InputSelect defaultValue={practice !== null && role === 'ADMIN' ? practice.agentId : practice !== null && role === 'AGENTE' ? authData.id : null} disabled={isDisable} name="agentId" data={role === 'AGENTE' ? [{
								index: 0,
								value: authData.id,
								label: authData.username
							}] : agents} label="Agente" isRequired={false} register={register} />
						</div>
						: null}

					<div className="col-md-4 d-none">
						<label className="d-block">&nbsp;</label>
						<label htmlFor="paid"><input disabled={isDisable} type="checkbox" defaultChecked={practice !== null ? practice.piad : false} name="paid" id="paid" ref={register} /> Pagato?</label>
					</div>

					<div className="col-md-12 mt-2">
						<InputTextArea defaultValue={practice !== null ? practice.notes : null} disabled={isDisable} name="notes" label="Note" register={register} />
					</div>
				</div>

				{
					role === 'ADMIN' ?
						<>
							<hr />

							<div className="top-page">
								<h3 className="top-page-title">Impostazioni ADMIN</h3>
							</div>

							<div className="row mt-2">
								<div className="col-md-8">
									<div className="row">
										<div className="col-md-6">
											<InputSelect defaultValue={practice !== null ? practice.status : null} name="status" label="Stato Pratica" data={selectStatus} onChange={onChangeStatus} register={register} />
										</div>
										<div className="col-md-6">
											<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateStartTime : null} name="dateStartTime" label="Data inizio timer" isRequired={false} register={register} />
										</div>
									</div>

									{practice.expired_at !== null ? <p style={{ marginBottom: 0, marginTop: 15 }}>Data Scadenza: <strong>{moment(practice.expired_at).format('DD/MM/YYYY')}</strong></p> : null}
									<br />
									<label htmlFor="judicial"><input disabled={isDisable} type="checkbox" defaultChecked={practice !== null ? practice.judicial : false} name="judicial" id="judicial" ref={register} /> Giudiziale?</label>
								</div>
							</div>

							{
								status === 'ACCETTAZIONE' ?
									<>
										<hr />

										<div className="row mb-3">
											<div className="col-md-4">
												<InputNumber validation={null} type="number" step="0.01" price={true} defaultValue={practice !== null ? practice.importoRecuperato : 0} disabled={isDisable} name="importoRecuperato" label="Importo Recuperato" onChange={setImportRec} register={register} />
											</div>

											<div className="col-md-4">
												<InputNumber validation={null} type="number" step="0.01" perc={true} defaultValue={practice !== null ? practice.sconto : 0} disabled={isDisable} name="sconto" label="Sconto" onChange={setDiscount} register={register} />
											</div>

											<div className="col-md-4">
												<InputDate disabled={isDisable} onChange={setDatePayedEnd} defaultValue={practice !== null ? practice.datePayedEnd : null} name="datePayedEnd" label="Data Pagamento" isRequired={false} register={register} />
											</div>
										</div>

										<p>Importo Fatturato: <strong>&euro; {fattImportText}</strong></p>
										<p>Importo Finale: <strong>&euro; {finalImportText}</strong></p>
										{
											compAgent !== null ? <p>Compenso Agente: <strong>&euro; {parseFloat((finalImportText * compAgent) / 100).toFixed(2)}</strong></p> : null
										}
										<p>Data Pagamento: <strong>{moment(datePayedEnd).format('DD/MM/YYYY')}</strong></p>

									</>
									: null
							}

							{
								status === 'RECLAMO' ?
									<>
										<hr />

										<div className="row mb-3">
											<div className="col-md-4">
												<InputNumber validation={null} type="number" step="0.01" price={true} defaultValue={practice !== null ? practice.importRequest : 0} disabled={isDisable} name="importRequest" label="Importo Richiesto" register={register} />
											</div>
										</div>
									</>
									: null
							}

							{
								generableFiles !== null ?
									<>
										<hr />

										<div className="top-page">
											<h3 className="top-page-title">Generazione File</h3>
										</div>
										<div className="row">
											<div className="col-md-4">
												<InputSelect name="type-file-to-generate" data={generableFiles} onChange={onChangeGenerateFile} placeholder="Seleziona file da generare" />
											</div>
										</div>
									</>
									: null
							}

							{generateFile !== null && showForm === 0 ? <FormReclamo handleGenerateFile={handleGenerateFile} generateFile={generateFile} /> : null}
							{generateFile !== null && showForm === 1 ? <AbfDone handleGenerateFile={handleGenerateFile} generateFile={generateFile} /> : null}
							{generateFile !== null && showForm === 2 ? <AbfRequest handleGenerateFile={handleGenerateFile} generateFile={generateFile} /> : null}
						</>
						: null
				}
			</StepForm>

			<hr />


			{ stepOpened !== 0 ? <input type="button" className="btn btn-dark" value="Precedente" disabled={stepOpened < 1} onClick={() => setStepOpened(stepOpened - 1)} /> : null}
			{ stepOpened !== 0 ? <input type="button" className="btn btn-primary mx-2" value="Prossimo" onClick={() => setStepOpened(stepOpened + 1)} /> : null}
			{ stepOpened === 0 ? <input type="submit" className={stepOpened === 0 ? 'btn btn-primary mx-2' : 'btn btn-primary'} value="Conferma" /> : null}
		</form>
	)
}

export default FormPracticeLoans;
