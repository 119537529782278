import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faFileInvoice, faSignOutAlt, faDatabase, faComments, faArchive, faCalendar, faUsers, faUniversity } from '@fortawesome/free-solid-svg-icons';

import { SidebarMain, SidebarLink } from './sidebar-components';
import EthosLogo from '../../media/logo.png';

const Sidebar = ({ role }) => {
	const handleLogout = () => {
		localStorage.removeItem('cs_CS_access_token');
		window.location.href = '/';
	}

	return (
		<SidebarMain>
			<div className="d-flex flex-column h-100">
				<img src={EthosLogo} alt="Logo ethos" style={{ width: '100%' }} />

				<hr />

				<NavLink to="/">ETHOS</NavLink>

				<hr />

				<div className="sidebar-link">
					<>
						<NavLink to="/customers">
							<SidebarLink>
								<FontAwesomeIcon icon={faUser} />
								<span>Clienti</span>
							</SidebarLink>
						</NavLink>
						<NavLink to="/banks">
							<SidebarLink>
								<FontAwesomeIcon icon={faUniversity} />
								<span>Banche</span>
							</SidebarLink>
						</NavLink>
						<NavLink to="/practices-cqs">
							<SidebarLink>
								<FontAwesomeIcon icon={faFileInvoice} />
								<span>Cessione del Quinto</span>
							</SidebarLink>
						</NavLink>
						<NavLink to="/practices-loans">
							<SidebarLink>
								<FontAwesomeIcon icon={faFileInvoice} />
								<span>Prestito Personale</span>
							</SidebarLink>
						</NavLink>
						<NavLink to="/practices-debts">
							<SidebarLink>
								<FontAwesomeIcon icon={faFileInvoice} />
								<span>Gestione Debito</span>
							</SidebarLink>
						</NavLink>
						<NavLink to="/calendar">
							<SidebarLink>
								<FontAwesomeIcon icon={faCalendar} />
								<span>Calendario</span>
							</SidebarLink>
						</NavLink>
						<NavLink to="/tickets">
							<SidebarLink>
								<FontAwesomeIcon icon={faComments} />
								<span>Ticket</span>
							</SidebarLink>
						</NavLink>
						<NavLink to="/archives/my-file?path=/Altri-File/">
							<SidebarLink>
								<FontAwesomeIcon icon={faArchive} />
								<span>File</span>
							</SidebarLink>
						</NavLink>
					</>

					{
						role === 'ADMIN' ?
							<>
								<NavLink to="/archives?path=/">
									<SidebarLink>
										<FontAwesomeIcon icon={faArchive} />
										<span>Archivio</span>
									</SidebarLink>
								</NavLink>
								<NavLink to="/database">
									<SidebarLink>
										<FontAwesomeIcon icon={faDatabase} />
										<span>Database</span>
									</SidebarLink>
								</NavLink>
								<NavLink to="/users">
									<SidebarLink>
										<FontAwesomeIcon icon={faUsers} />
										<span>Utenti</span>
									</SidebarLink>
								</NavLink>
							</>
							: null
					}
				</div>

				<div className="sidebar-account mt-auto">
					<SidebarLink href="#" onClick={handleLogout}>
						<FontAwesomeIcon icon={faSignOutAlt} />
						<span>Disconetti</span>
					</SidebarLink>
				</div>
			</div>
		</SidebarMain>
	)
}

export default Sidebar;