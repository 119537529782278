import React, { useEffect, useState } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';

import { InputText, InputSelect, InputEmail, InputTextArea, InputDate, InputNumber } from '../../forms';
import { useBank } from '../../../contexts/banks-context';
import { useCustomer } from '../../../contexts/customer-context';
import { useUser } from '../../../contexts/user-context';
import { useAuth } from '../../../contexts/auth-context';

import Calcs from './calcs';

const StepForm = styled.section``;

const FormPracticeCqs = ({ role = undefined, isDisable = false, practice = null, handleSave, handleGenerateFile = () => { } }) => {
	const { getCustomersForPractice } = useCustomer();
	const authData = useAuth();
	const { getUsersPractice, getSingleUser } = useUser();
	const { getBanksForPractice } = useBank();
	const [stepOpened, setStepOpened] = useState(0);
	const [customers, setCustomers] = useState([]);
	const [banks, setBanks] = useState([]);
	const [generateFile, setGenerateFile] = useState(null);
	const [agents, setAgents] = useState(null);
	const [btnGenerationTxt, setBtnGenerationTxt] = useState('Genera');
	const agent = practice !== null ? practice.agentId : null;
	const [discount, setDiscount] = useState(practice !== null ? practice.sconto : 0);
	const [importRec, setImportRec] = useState(practice !== null ? practice.importoRecuperato : 0);
	const [fattImportText, setFattImportText] = useState(0);
	const [finalImportText, setFinalImportText] = useState(0);
	const [status, setStatus] = useState(practice !== null ? practice.status : null);
	const [compAgent, setCompAgent] = useState(0);
	const [datePayedEnd, setDatePayedEnd] = useState(practice !== null ? practice.datePayedEnd : null)

	// calcs section
	const [showCalcs, setShowCalcs] = useState(false);
	const [haveCalcs, setHaveCalcs] = useState(practice === null ? false : practice.haveCalcs);
	const [calcs, setCalcs] = useState(practice === null ? null : practice.calcs);
	const [editCommBanc, setEditCommBanc] = useState(practice === null ? 0 : practice.calcs.editCommBanc);
	const [editCommInt, setEditCommInt] = useState(practice === null ? 0 : practice.calcs.editCommInt);
	const [editAss, setEditAss] = useState(practice === null ? 0 : practice.calcs.editAss);
	const [importLett, setImportLett] = useState(practice === null ? 0 : practice.calcs.importLett);
	const [editSpesa, setEditSpesa] = useState(practice === null ? 0 : practice.calcs.editSpesa);
	const [rncImport, setRncImport] = useState(practice === null ? 0 : practice.calcs.rncImport);
	const [rncImportLett, setRncImportLett] = useState(practice === null ? 0 : practice.calcs.rncImportLett);

	const [importoPrestito, setImportoPrestito] = useState(practice !== null ? practice.importLoan : 0.00);
	const [nrRate, setNrRate] = useState(practice !== null ? practice.nInstallments : 0.00);

	const { register, handleSubmit, control } = useForm({});

	useEffect(() => {
		const getCustomersPractice = async () => {
			const { data, error } = await getCustomersForPractice();

			if (error !== null) return alert('Error to load customers');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					value: item._id,
					label: `${item.first_name} ${item.last_name}`.toUpperCase()
				}
			});

			setCustomers(dataToObj);
		}

		const getBanks = async () => {
			const { data, error } = await getBanksForPractice();

			if (error !== null) return alert('Error to load banks');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					pec: item.pec,
					value: item.name,
					label: item.name
				}
			});

			setBanks(dataToObj);
		}

		const initUser = async () => {
			const { data } = await getUsersPractice('AGENTE');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					value: item._id,
					label: item.username
				}
			});

			setAgents(dataToObj);
		}

		const getAgent = async () => {
			if (practice.agentId !== null) {
				const { data } = await getSingleUser(practice.agentId);
				if (data !== null) {
					setCompAgent(data.comp);
				} else {
					setCompAgent(0);
				}
			}
		}

		if (practice !== null) {
			getAgent();
		}

		initUser();
		getCustomersPractice();
		getBanks();
	}, [getCustomersForPractice, getSingleUser, getBanksForPractice, getUsersPractice, practice]);

	useEffect(() => {
		if (practice !== null) {
			const getAgentComp = async (id) => {
				const { data } = await getSingleUser(id);
				if (data !== null) {
					setCompAgent(data.comp);
				} else {
					setCompAgent(0);
				}
			};

			if (practice.agentId !== null) {
				getAgentComp(practice.agentId);
			}

			const fattImport = parseFloat(importRec * 0.40 * 1.22).toFixed(2);
			const finalImport = parseFloat(fattImport / (1 + (discount / 100))).toFixed(2);
			console.log(1 + (discount / 100))

			setFattImportText(fattImport);
			setFinalImportText(finalImport);
		}
	}, [discount, importRec, agent, getSingleUser, practice]);

	const onSubmit = async (data) => {
		let obj = data;

		// fix 
		for (var p in obj) {
			if (obj[p] === '') {
				obj[p] = undefined;
			}
		}

		if (haveCalcs && calcs !== null) {
			obj['haveCalcs'] = true;
			obj['calcs'] = {
				...calcs,
				editCommBanc,
				editCommInt,
				editAss,
				editSpesa,
				rncImport,
				rncImportLett,
				importLett
			}
		} else {
			obj['haveCalcs'] = false;
			obj['calcs'] = {};
		}

		await handleSave(obj);
	}

	const selectStatus = [
		{ value: 'BOZZA', label: 'BOZZA' },
		{ value: '119', label: '119' },
		{ value: 'IN CORSO', label: 'IN CORSO' },
		{ value: 'DA ESAMINARE', label: 'DA ESAMINARE' },
		{ value: 'RECLAMO', label: 'RECLAMO' },
		{ value: 'SOLLECITO', label: 'SOLLECITO' },
		{ value: 'KO', label: 'KO' },
		{ value: 'ACCETTAZIONE', label: 'ACCETTAZIONE' },
		{ value: 'CONTRODEDUZIONE', label: 'CONTRODEDUZIONE' },
		{ value: 'ABF', label: 'ABF' },
		{ value: 'RECUPERATA', label: 'RECUPERATA' },
		{ value: '40%', label: 'IN CORSO 40%' }
	];

	const generableFiles = [
		{ value: 'Cessione-Del-Quinto/RECLAMO/Base-Reclamo-CQS-2020-Lexitor.docx', label: 'RECLAMO' }
	]

	const handleOnChangeBank = async (value) => {
		console.log(value)
		const pec = banks.filter(item => item.label === value)[0].pec;
		document.getElementById('pecBank').value = pec;
	}

	const handleGenerateFileLocal = async () => {
		setBtnGenerationTxt('Genero...');
		await handleGenerateFile(generateFile);
		setBtnGenerationTxt('Genera');
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>

			<div className="alert alert-info">
				<strong>TUTTI I CAMPI SEGUITI DA * SONO OBBLIGATORI</strong>
			</div>

			{
				practice !== null ?
				<div className={moment() >	moment(practice.dateStart).add((((moment(practice.dateEnd).year() - moment(practice.dateStart).year()) * 40) / 100), 'years') ? 'alert alert-success' : 'alert alert-danger'}>
					{
						moment() >	moment(practice.dateStart).add((((moment(practice.dateEnd).year() - moment(practice.dateStart).year()) * 40) / 100), 'years') ? 'ATTENZIONE! Sono decorsi 4 anni dalla data di inizio contratto' : 'ATTENZIONE! Non sono decorsi 4 anni dalla data di inizio contratto'
					}
				</div>
				: null
			}

			<StepForm style={{ display: stepOpened === 0 ? 'block' : 'none' }}>
				<div className="row">
					<div className="col-md-4">
						{customers.length > 0 ?
							<InputSelect control={control} defaultValue={practice !== null ? practice.clientId : null} name="clientId" data={practice !== null ? [ ...customers.filter(item => item.value === practice.clientId) ] : customers} label="Cliente" isRequired={true} register={register} />
							: null}
					</div>

					<div className="col-md-4">
						<InputText defaultValue={practice !== null ? practice.nContract : null} disabled={isDisable} name="nContract" label="N. Contratto" isRequired={true} register={register} />
					</div>

					<div className="col-md-4">
						{banks.length > 0 ?
							<InputSelect control={control} defaultValue={practice !== null ? practice.bank : null} disabled={isDisable} name="bank" data={banks} label="Finanziaria/Banca" isRequired={true} onChange={handleOnChangeBank} register={register} />
							: null}
					</div>

				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputText defaultValue={practice !== null ? practice.branch : null} disabled={isDisable} name="branch" label="Filiale" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateStart : null} name="dateStart" label="Data inizio Contratto" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateEnd : null} name="dateEnd" label="Data fine Contratto" isRequired={false} register={register} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateClose : null} name="dateClose" label="Data chiusura Contratto" isRequired={false} register={register} />
					</div>

					<div className="col-md-4">
						<InputEmail defaultValue={practice !== null ? practice.pecBank : null} disabled={isDisable} name="pecBank" label="PEC Banca" register={register} />
					</div>
					
					{agents !== null && (role === 'ADMIN' || role === 'AGENTE') ?
						<div className="col-md-4">
							<InputSelect defaultValue={practice !== null && role === 'ADMIN' ? practice.agentId : practice !== null && role === 'AGENTE' ? authData.id : null} disabled={isDisable} name="agentId" data={role === 'AGENTE' ? [{
								index: 0,
								value: authData.id,
								label: authData.username
							}] : agents} label="Agente" isRequired={false} register={register} />
						</div>
						: null}

					<div className="col-md-12 mt-2">
						<InputTextArea defaultValue={practice !== null ? practice.notes : null} disabled={isDisable} name="notes" label="Note" register={register} />
					</div>
				</div>

				<hr />

				<h5 className="fw-bold">Dati Finanziamento</h5>
				<div className="row">
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} defaultValue={practice !== null ? practice.importLoan : null} disabled={isDisable} name="importLoan" label="Importo Prestito" register={register} onChange={setImportoPrestito} />
					</div>
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="1" isRequired={false} defaultValue={practice !== null ? practice.nInstallments : null} disabled={isDisable} name="nInstallments" label="Nr. Rate" register={register} onChange={setNrRate} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} price={true} defaultValue={practice !== null ? practice.singleInstallmentImport : null} disabled={isDisable} name="singleInstallmentImport" label="Importo Singola Rata" register={register} value={parseFloat(importoPrestito / nrRate).toFixed(2)} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} defaultValue={practice !== null ? practice.tan : null} disabled={isDisable} name="tan" label="TAN" register={register} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} defaultValue={practice !== null ? practice.taeg : null} disabled={isDisable} name="taeg" label="TAEG" register={register} />
					</div>

					<div className="col-md-4">
						<InputNumber validation={null} type="number" step="0.01" isRequired={false} perc={true} defaultValue={practice !== null ? practice.teg : null} disabled={isDisable} name="teg" label="TEG" register={register} />
					</div>
				</div>

				{
					role === 'ADMIN' ?
						<>
							<hr />

							<div className="top-page">
								<h3 className="top-page-title">Impostazioni ADMIN</h3>
							</div>

							<div className="row mt-2">
								<div className="col-md-8">
									<div className="row">
										<div className="col-md-6">
											<InputSelect defaultValue={practice !== null ? practice.status : null} name="status" label="Stato Pratica" onChange={setStatus} data={selectStatus} register={register} />
										</div>
										<div className="col-md-6">
											<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dateStartTime : null} name="dateStartTime" label="Data inizio timer" isRequired={false} register={register} />
										</div>
									</div>
									{practice.expired_at !== null ? <p style={{ marginBottom: 0, marginTop: 15 }}>Data Scadenza: <strong>{moment(practice.expired_at).format('DD/MM/YYYY')}</strong></p> : null}
								</div>
								<div className="col-md-2">
									<label className="d-block">&nbsp;</label>
									<button type="button" className="w-100 btn btn-primary" onClick={() => setShowCalcs(!showCalcs)}>Genera Calcoli</button>
									{showCalcs ? <Calcs calcs={calcs} setHaveCalcs={setHaveCalcs} setShowCalcs={setShowCalcs} setCalcs={setCalcs} /> : null}
								</div>
							</div>

							{
								status === 'RECLAMO' || status === 'RECUPERATA' || status === 'ACCETTAZIONE' || status === 'CONTRODEDUZIONE' || status === 'SOLLECITO' || status === 'ABF' || status === 'KO' ?
									<>
										<hr />

										<div className="row mb-3">
											<div className="col-md-4">
												<InputNumber validation={null} type="number" step="0.01" price={true} defaultValue={practice !== null ? practice.importRequest : 0} disabled={isDisable} name="importRequest" label="Importo Richiesto" register={register} />
											</div>
										</div>
									</>
									: null
							}

							{
								status === 'ACCETTAZIONE' ?
									<>
										<hr />

										<div className="row mb-3">
											<div className="col-md-4">
												<InputNumber validation={null} type="number" step="0.01" price={true} defaultValue={practice !== null ? practice.importoRecuperatoNew : 0} disabled={isDisable} name="importoRecuperatoNew" label="Importo Recuperato" onChange={setImportRec} register={register} />
											</div>
											<div className="col-md-4">
												<InputDate disabled={isDisable} defaultValue={practice !== null ? practice.dataSendAcc : null} name="dataSendAcc" label="Data Invio Accettazione" isRequired={false} register={register} />
											</div>
										</div>
									</>
									: null
							}

							{
								status === 'RECUPERATA' ?
									<>
										<hr />

										<div className="row mb-3">
											<div className="col-md-4">
												<InputNumber validation={null} type="number" step="0.01" price={true} defaultValue={practice !== null ? practice.importoRecuperato : 0} disabled={isDisable} name="importoRecuperato" label="Importo Recuperato" onChange={setImportRec} register={register} />
											</div>

											<div className="col-md-4">
												<InputNumber validation={null} type="number" step="0.01" defaultValue={practice !== null ? practice.sconto : 0} disabled={isDisable} name="sconto" label="Sconto" perc={true} onChange={setDiscount} register={register} />
											</div>

											<div className="col-md-4">
												<InputDate onChange={setDatePayedEnd} disabled={isDisable} defaultValue={practice !== null ? practice.datePayedEnd : null} name="datePayedEnd" label="Data Pagamento" isRequired={false} register={register} />
											</div>
										</div>

										<p>Importo Fatturato: <strong>&euro; {fattImportText}</strong></p>
										<p>Importo Finale: <strong>&euro; {finalImportText}</strong></p>
										{
											compAgent !== null ? <p>Compenso Agente: <strong>&euro; {parseFloat((finalImportText * compAgent) / 100).toFixed(2)}</strong></p> : null
										}
										<p>Data Pagamento: <strong>{moment(datePayedEnd).format('DD/MM/YYYY')}</strong></p>

									</>
									: null
							}

							{
								haveCalcs ?
									<>
										<hr />
										<table className="table table-striped">
											<thead className="thead-dark">
												<tr>
													<th>Ristorni</th>
													<th></th>
													<th>Già Stornati</th>
													<th></th>
													<th>Da Richiedere</th>
												</tr>
											</thead>
											<tbody>
												<tr>
													<td>Spesa commissione bancaria (Una Tantum)</td>
													<td>&euro; {parseFloat(calcs.expenseCommFin * calcs.percSpes).toFixed(2)}</td>
													<td><input type="number" defaultValue={editCommBanc} step="0.01" onChange={e => setEditCommBanc(e.target.value)} /></td>
													<td></td>
													<td>&euro; {parseFloat((calcs.expenseCommFin * calcs.percSpes) - editCommBanc).toFixed(2)}</td>
												</tr>
												<tr>
													<td>Spesa commissione intermediario del credito (Una Tantum)</td>
													<td>&euro; {parseFloat(calcs.expenseCommInt * calcs.percSpes).toFixed(2)}</td>
													<td><input type="number" defaultValue={editCommInt} step="0.01" onChange={e => setEditCommInt(e.target.value)} /></td>
													<td></td>
													<td>&euro; {parseFloat((calcs.expenseCommInt * calcs.percSpes) - editCommInt).toFixed(2)}</td>
												</tr>
												<tr>
													<td>Spesa assicurazione rimborso credito (Una Tantum)</td>
													<td>&euro; {parseFloat(calcs.expenseAssRim * calcs.percSpes).toFixed(2)}</td>
													<td><input type="number" defaultValue={editAss} step="0.01" onChange={e => setEditAss(e.target.value)} /></td>
													<td></td>
													<td>&euro; {parseFloat((calcs.expenseAssRim * calcs.percSpes) - editAss).toFixed(2)}</td>
												</tr>
												<tr>
													<td>Spese Istruttoria</td>
													<td>&euro; {parseFloat(calcs.spesa * calcs.percSpes).toFixed(2)}</td>
													<td><input type="number" defaultValue={editSpesa} step="0.01" onChange={e => setEditSpesa(e.target.value)} /></td>
													<td></td>
													<td>&euro; {parseFloat((calcs.spesa * calcs.percSpes) - editSpesa).toFixed(2)}</td>
												</tr>
												<tr>
													<td>-</td>
													<td>-</td>
													<td>-</td>
													<td><strong>Totale</strong></td>
													<td><strong>&euro; {parseFloat(((calcs.expenseCommFin * calcs.percSpes) - editCommBanc) + ((calcs.expenseCommInt * calcs.percSpes) - editCommInt) + ((calcs.expenseAssRim * calcs.percSpes) - editAss) + ((calcs.spesa * calcs.percSpes) - editSpesa)).toFixed(2)}</strong></td>
												</tr>
												<tr>
													<td>-</td>
													<td>-</td>
													<td>-</td>
													<td><strong>Importo in Lettere</strong></td>
													<td><input type="text" defaultValue={importLett} onChange={e => setImportLett(e.target.value)} /></td>
												</tr>
											</tbody>
										</table>

										<hr />

										<h5 className="fw-bold">Rate non contabilizzate</h5>
										<div className="row">
											<div className="col-md-3">
												<InputNumber validation={null} price={true} step="0.01" type="number" label="Importo" name="rncImport" defaultValue={rncImport} onChange={setRncImport} />
											</div>
											<div className="col-md-3">
												<InputText label="Importo in Lettere" name="rncImportLett" defaultValue={rncImportLett} onChange={setRncImportLett} />
											</div>
										</div>

										<hr />

										<div className="row">
											<div className="col-md-4">
												<InputSelect name="type-file-to-generate" data={generableFiles} label="Seleziona modello" onChange={setGenerateFile} placeholder="Seleziona file da generare" />
											</div>
											<div className="col-md-2">
												<label className="d-block">&nbsp;</label>
												<button type="button" onClick={handleGenerateFileLocal} className="btn btn-primary">{btnGenerationTxt}</button>
											</div>
										</div>
									</>
									: null}
						</>
						: null
				}
			</StepForm>

			<hr />


			{ stepOpened !== 0 ? <input type="button" className="btn btn-dark" value="Precedente" disabled={stepOpened < 1} onClick={() => setStepOpened(stepOpened - 1)} /> : null}
			{ stepOpened !== 0 ? <input type="button" className="btn btn-primary mx-2" value="Prossimo" onClick={() => setStepOpened(stepOpened + 1)} /> : null}
			{ stepOpened === 0 ? <input type="submit" className={stepOpened === 0 ? 'btn btn-primary mx-2' : 'btn btn-primary'} value="Conferma" /> : null}
		</form>
	)
}

export default FormPracticeCqs;
