import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Sidebar from '../sidebar';
import { useAuth } from '../../contexts/auth-context';
import { GoogleProvider } from '../../contexts/google-context';
import { WrapperDashboard, WrapperPages } from '../custom-components';

// pages
import Home from '../../pages/Home';
import Customers from '../../pages/Customers';
import PracticesCqs from '../../pages/PracticesCqs';
import PracticesLoans from '../../pages/PracticesLoans';
import PracticesDebts from '../../pages/PracticesDebts';
import Users from '../../pages/Users';
import Database from '../../pages/Database';
import Tickets from '../../pages/Tickets';
import Archives from '../../pages/Archives';
import Calendar from '../../pages/Calendar';
import Banks from '../../pages/Banks';

const Dashboard = () => {
	const { role } = useAuth();

	return (
		<BrowserRouter>
			<WrapperDashboard>
				<Sidebar role={role} />
				<WrapperPages>
					<GoogleProvider>
						<Switch>
							<Route exact path="/" component={Home} />
							<Route path="/customers" component={Customers} />
							<Route path="/practices-cqs" component={PracticesCqs} />
							<Route path="/practices-loans" component={PracticesLoans} />
							<Route path="/practices-debts" component={PracticesDebts} />
							<Route path="/database" component={Database} />
							<Route path="/tickets" component={Tickets} />
							<Route path="/archives" component={Archives} />
							<Route path="/calendar" component={Calendar} />
							<Route path="/banks" component={Banks} />
							<Route path="/users" component={Users} />
						</Switch>
					</GoogleProvider>
				</WrapperPages>
			</WrapperDashboard>
		</BrowserRouter>
	)
}

export default Dashboard;