import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faExclamationTriangle, faTrash } from '@fortawesome/free-solid-svg-icons';

import { useAuth } from '../../../contexts/auth-context';
import { usePracticesDebts } from '../../../contexts/practices-debts-context';
import { useBank } from '../../../contexts/banks-context';
import { useUser } from '../../../contexts/user-context';
import { useCustomer } from '../../../contexts/customer-context';
import NewPracticeDebts from '../new-practice-debts';
import { getPagination } from '../../../utils/functions';

const ListPracticesDebts = (props) => {
	const { role } = useAuth();
	const { getCustomersForPractice } = useCustomer();
	const { getUsersPractice } = useUser();
	const { getBanksForPractice } = useBank();
	const { getPractices, deletePractice, generateExcel } = usePracticesDebts();
	const [practices, setPractices] = useState(null)
	const [showNewPracticeDebts, setShowNewPracticeDebts] = useState(false);
	const [filter, setFilter] = useState(null);
	const [filiale, setFiliale] = useState(null);
	const [search, setSearch] = useState(null);
	const [customer, setCustomer] = useState(null);
	const [user, setUser] = useState(null);
	const [users, setUsers] = useState([]);
	const [banks, setBanks] = useState([]);
	const [customers, setCustomers] = useState([]);

	const limit = 50;
	const [offset, setOffset] = useState(0);
	const [pagination, setPagination] = useState(null);

	useEffect(() => {
		const fetchCustomer = async () => {
			const { data, error } = await getCustomersForPractice();

			if (error !== null) return alert('Error to load customers');

			setCustomers(data);
		}

		const _getPractices = async () => {
			const { data, error } = await getPractices(search, filter === 'null' ? null : filter, filiale === 'null' ? null : filiale, user === 'null' ? null : user, customer === 'null' ? null : customer, limit, offset);

			if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);

			setPractices(data.data);
			getPagination(data.length, limit, setOffset, setPagination);
		}

		const getUsers = async () => {
			const { data } = await getUsersPractice();
			setUsers(data);
		}

		const getBanks = async () => {
			const { data } = await getBanksForPractice();
			setBanks(data);
		}

		_getPractices();
		getBanks();
		getUsers();
		fetchCustomer();
	}, [search, filter, limit, filiale, user, offset, customer, getPractices, getBanksForPractice, getUsersPractice, getCustomersForPractice]);

	const handleSearch = async () => {
	}

	const handleDeletePractices = async (id) => {
		const confirmDelete = window.confirm('Sei sicuro di voler eliminare la pratica?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deletePractice(id);

		if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);

		window.location.reload();
	}

	const handleGenerateExcel = async () => {
		const { data, error } = await generateExcel(search, filter === 'null' ? null : filter, filiale === 'null' ? null : filiale, user === 'null' ? null : user);


		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.data);
		const filename = 'Pratiche-Gestione-Debito.xlsx';

		const blob = new Blob([buffer], {});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename;

		document.body.appendChild(element);

		element.click();
		element.remove();
		console.log(data);
	}

	return (
		<div className="list-practices-page">
			<div className="top-bar">
				<button className="btn btn-primary" onClick={() => setShowNewPracticeDebts(!showNewPracticeDebts)}>Nuova Pratica</button>

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setFilter(e.target.value)}>
					<option value="null">Seleziona un filtro</option>
					{role === 'AVVOCATO' ? <option value="BEBTS-LEGAL">Presiti Giudiziali</option> : null}
					<option value='BOZZA'>BOZZA</option>
					<option value='ISTRUTTORIA'>ISTRUTTORIA</option>
					<option value='INVIO RICHIESTA'>INVIO RICHIESTA</option>
					<option value='ANALISI CONTRATTO'>ANALISI CONTRATTO</option>
					<option value='INVIO OFFERTA SALDOESTRALCIO'>INVIO OFFERTA SALDOESTRALCIO</option>
					<option value='DEBITO CHIUSO'>DEBITO CHIUSO</option>
					<option value="SCADENZA:1">SCADENZA</option>
				</select>

				{
					String(filter).split(':')[0] === 'SCADENZA' ?
						<select className="form-select d-inline w-auto mx-2" onChange={(e) => setFilter(e.target.value)}>
							<option value="SCADENZA:1">Gennaio</option>
							<option value="SCADENZA:2">Febbraio</option>
							<option value="SCADENZA:3">Marzo</option>
							<option value="SCADENZA:4">Aprile</option>
							<option value="SCADENZA:5">Maggio</option>
							<option value="SCADENZA:6">Giugno</option>
							<option value="SCADENZA:7">Luglio</option>
							<option value="SCADENZA:8">Agosto</option>
							<option value="SCADENZA:9">Settembre</option>
							<option value="SCADENZA:10">Ottobre</option>
							<option value="SCADENZA:11">Novembre</option>
							<option value="SCADENZA:12">Dicembre</option>
						</select>
						: null
				}

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setFiliale(e.target.value)}>
					<option value="null">Filtra per banca</option>
					{
						banks.map((item, index) => <option key={index} value={item.name}>{item.name}</option>)
					}
				</select>

				{
					role === 'ADMIN' ?
						<select className="form-select d-inline w-auto mx-2" onChange={(e) => setUser(e.target.value)}>
							<option value="null">Filtra per utente</option>
							{
								users.map((item, index) => <option key={index} value={item._id}>{item.username}</option>)
							}
						</select>
						: null
				}

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setCustomer(e.target.value)}>
					<option value="null">Filtra per cliente</option>
					{
						customers.map((item, index) => <option key={index} value={item._id}>{`${item.first_name} ${item.last_name}`.toUpperCase()}</option>)
					}
				</select>

				<div className="wrapper-input-group w-auto d-inline-block">
					<div className="input-group">
						<input className="form-control" placeholder="Cerca una pratica" onChange={(e) => setSearch(e.target.value)} />
						<input className="btn btn-primary" type="submit" value="Cerca" onClick={handleSearch} />
					</div>
				</div>

				<button onClick={handleGenerateExcel} className="btn btn-primary mx-2">Genera XLSX</button>
			</div>

			<hr />

			<nav className="pagination-section">
				<ul className="pagination">
					<li className="page-item">
						<button className="page-link" onClick={() => { if (offset > 0) setOffset(offset - limit) }} aria-label="Previous">
							<span aria-hidden="true">&laquo;</span>
						</button>
					</li>
					{pagination}
					<li className="page-item">
						<button className="page-link" onClick={() => { setOffset(offset + limit) }} aria-label="Next">
							<span aria-hidden="true">&raquo;</span>
						</button>
					</li>
				</ul>
			</nav>

			<table className="table">
				<thead className="thead-dark">
					<tr>
						<th></th>
						<th></th>
						<th>N. Contratto</th>
						<th>Banca</th>
						<th>Cliente</th>
						<th>Operatore</th>
						<th>Stato</th>
						<th>Data Scadenza</th>
						<th>Data Creazione</th>
					</tr>
				</thead>
				<tbody className="practice-list">
					{practices !== null ?
						practices.map((item, index) => {
							return (
								<tr key={index} className={item.status === 'KO' ? 'red' : item.status === 'RECUPERATA' ? 'green' : item.status === 'IN CORSO' ? 'orange' : null}>
									<td>
										<NavLink to={`${props.match.path}/${item._id}`} className="btn btn-secondary"><FontAwesomeIcon icon={faEdit} /></NavLink>
										<button type="button" onClick={() => handleDeletePractices(item._id)} className="mx-2 btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
									</td>
									{
										item.expired_at !== null ?
											<td>{moment(item.expired_at) > moment() ? null : <FontAwesomeIcon icon={faExclamationTriangle} />}</td>
											: <td></td>
									}
									<td className="text-uppercase">{item.nContract}</td>
									<td className="text-uppercase">{item.bank}</td>
									<td className="text-uppercase">{`${item.customerdata[0].first_name} ${item.customerdata[0].last_name}`}</td>
									<td>{item.userdata[0].username}</td>
									<td className="text-uppercase">{item.status}</td>
									<td className="text-uppercase">{item.expired_at ? moment(item.expired_at).format('DD/MM/YYYY') : 'n/a'}</td>
									<td className="text-uppercase">{moment(item.created_at).format('DD/MM/YYYY')}</td>
								</tr>
							)
						})
						: null}
				</tbody>
			</table>

			{showNewPracticeDebts ? <NewPracticeDebts setShowNewPracticeDebts={setShowNewPracticeDebts} setPractices={setPractices} /> : null}

		</div>
	)
}

export default ListPracticesDebts;