export const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT || `http://localhost:5000/api`;
export const AUTH_ENDPOINT = 'users';
export const USERS_ENDPOINT = 'users';
export const CUSTOMERS_ENDPOINT = 'customers';
export const PRACTICES_CQS_ENDPOINT = 'cqs';
export const PRACTICES_LOANS_ENDPOINT = 'loans';
export const PRACTICES_DEBTS_ENDPOINT = 'debts';
export const ARCHIVES_ENDPOINT = 'archives';
export const TICKETS_ENDPOINT = 'tickets';
export const GOOGLE_ENDPOINT = 'googleapis';
export const GCALENDAR_ENDPOINT = 'gcalendar';
export const BANKS_ENDPOINT = 'banks';