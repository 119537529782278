import React, { useEffect, useState } from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../../../custom-components';
import { InputNumber } from '../../../../../forms';

const AddTaxCollection = ({ setOpenAddTaxCollection, handleSaveItems }) => {
	const [debit, setDebit] = useState(0);
	const [nFolder, setNFolder] = useState(0);

	const handleAddItem = async () => {
		const data = {
			debit,
			nFolder
		}

		await handleSaveItems(data);
		setOpenAddTaxCollection(false);
	}

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo TaxCollection</h3>
					<button className="close-button" onClick={() => setOpenAddTaxCollection(false)}>&times;</button>
				</div>

				<hr />

				<div className="row">
					<div className="col-md-3">
						<InputNumber price={true} validation={null} type="number" label="Import Debito Tributario" name="debit" step="0.01" onChange={setDebit} />
					</div>
					<div className="col-md-3">
						<InputNumber type="number" label="Nr. Cartella Esattoriale" name="nFolder" step="1" onChange={setNFolder} />
					</div>
				</div>

				<hr />

				<input onClick={handleAddItem} type="button" value="Aggiungi" className="btn btn-primary" />

			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

const TaxCollections = ({ disabled = false, taxCollectionItems = [], haveTaxCollection = false, setHaveTaxCollection = () => {}, setTaxCollectionItems = () => {}, handleGetTotalDebit = () => {} }) => {
	const [openAddTaxCollection, setOpenAddTaxCollection] = useState(false);
	const [viewedItems, setViewdItems] = useState(null);

	const handleSaveItems = async (data) => {
		setTaxCollectionItems([...taxCollectionItems, data]);
		handleGetTotalDebit();
	}

	useEffect(() => {
		const handleRemove = (item) => {
			const index = taxCollectionItems.indexOf(item);
			setTaxCollectionItems(items => items.filter((_val, i) => i !== index));
		}

		let data = [];

		taxCollectionItems.map((item, i) => {
			data.push(
				<tr key={i}>
					<td>&euro; {parseFloat(item.debit).toFixed(2)}</td>
					<td>{item.nFolder}</td>
					<td><button disabled={disabled} className="close-button d-block ms-auto" type="button" onClick={() => handleRemove(item)}>Rimuovi</button></td>
				</tr>
			)

			return {}
		})

		handleGetTotalDebit();
		setViewdItems(data);
	}, [taxCollectionItems, setTaxCollectionItems, handleGetTotalDebit, disabled]);

	return (
		<>
			<div className="d-flex flex-columns align-items-center">
				<label htmlFor="taxCollection" className="mb-2"><input disabled={disabled} onChange={() => setHaveTaxCollection(!haveTaxCollection)} type="checkbox" defaultChecked={haveTaxCollection} name="taxCollection" id="taxCollection" /> <span className="mx-2"> Cartelle Esattoriale?</span></label>
				{haveTaxCollection && taxCollectionItems.length < 10 && !disabled ? <button onClick={() => setOpenAddTaxCollection(!openAddTaxCollection)} type="button" className="btn btn-primary ms-auto">Aggiungi Cartella Esattoriale</button> : null}
			</div>

			{ openAddTaxCollection ? <AddTaxCollection setOpenAddTaxCollection={setOpenAddTaxCollection} handleSaveItems={handleSaveItems} /> : null}

			{ taxCollectionItems.length > 0 ?
				<div className="TaxCollection-items">
					<hr />

					<table className="table">
						<thead>
							<tr>
								<th>Importo Debito Tributario</th>
								<th>Nr. Cartella Esattoriale</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								viewedItems
							}
						</tbody>
					</table>
				</div>
				: null}
		</>
	)
}

export default TaxCollections;