import React, { useState } from 'react'

import { useAuth } from './auth-context';
import { httpPost, httpDelete, httpPatch, httpGet, httpDownload } from '../http'
import { PRACTICES_DEBTS_ENDPOINT } from '../constants/API_ENDPOINT'

const PracticesDebtsContext = React.createContext()

function PracticesDebtsProvider(props) {
	const { jwtToken } = useAuth();
	const [logs, setLogs] = useState([]);

	const getPractices = async (search = null, filter = null, filiale = null, user = null, customer = null, limit = 12, offset = 1) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practices = await httpGet(`${PRACTICES_DEBTS_ENDPOINT}`, jwtToken, {
				filter,
				filiale,
				search,
				limit,
				offset,
				user,
				customer
			});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getDebtsSummary = async () => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practices = await httpGet(`${PRACTICES_DEBTS_ENDPOINT}/summary`, jwtToken, {});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getSinglePractice = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpGet(`${PRACTICES_DEBTS_ENDPOINT}/${id}`, jwtToken);

			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};

			setLogs(practice.data.logs);
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const newPractice = async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpPost(`${PRACTICES_DEBTS_ENDPOINT}`, jwtToken, data);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const editPractice = async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpPatch(`${PRACTICES_DEBTS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deletePractice = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpDelete(`${PRACTICES_DEBTS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const uploadFileDebts = async (id, type, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${PRACTICES_DEBTS_ENDPOINT}/upload/${type}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const uploadCustomFileDebts = async (id, filename, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${PRACTICES_DEBTS_ENDPOINT}/upload-custom/${filename}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	} 

	const downloadFileDebts = async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${PRACTICES_DEBTS_ENDPOINT}/download/${type}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteFileDebts = async (id, type) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${PRACTICES_DEBTS_ENDPOINT}/delete/${type}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const downloadCustomFileDebts = async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${PRACTICES_DEBTS_ENDPOINT}/download-custom/${filename}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteCustomFileDebts = async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${PRACTICES_DEBTS_ENDPOINT}/delete-custom/${filename}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const uploadCustomFileDebtsPec = async (id, filename, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${PRACTICES_DEBTS_ENDPOINT}/upload-pec/${filename}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	} 

	const downloadCustomFileDebtsPec = async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${PRACTICES_DEBTS_ENDPOINT}/download-pec/${filename}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteCustomFileDebtsPec = async (id, filename) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${PRACTICES_DEBTS_ENDPOINT}/delete-pec/${filename}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const resetExpireTimer = async (id, date) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practice = await httpGet(`${PRACTICES_DEBTS_ENDPOINT}/reset-expire/${id}`, jwtToken, { date });

			obj = {
				data: practice.data,
				error: null,
				meta: null,
				status: practice.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const generateFileByType = async (id, type, path) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDownload(`${PRACTICES_DEBTS_ENDPOINT}/generate/${id}/${type}`, jwtToken, { path });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const generateExcel = async (search = null, filter = null, filiale = null, user = null) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const practices = await httpGet(`${PRACTICES_DEBTS_ENDPOINT}/generate-xlsx`, jwtToken, {
				filter,
				filiale,
				search,
				user
			});
			obj = {
				data: practices.data,
				error: null,
				meta: null,
				status: practices.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const setPayedAndDatePayedDebt = async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${PRACTICES_DEBTS_ENDPOINT}/set-payed-agent/${id}`, jwtToken, { ...data });
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status
			}
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	return (
		<PracticesDebtsContext.Provider
			value={{
				getPractices,
				getSinglePractice,
				newPractice,
				editPractice,
				deletePractice,
				uploadFileDebts,
				downloadFileDebts,
				deleteFileDebts,
				uploadCustomFileDebts,
				downloadCustomFileDebts,
				deleteCustomFileDebts,
				uploadCustomFileDebtsPec,
				generateExcel,
				downloadCustomFileDebtsPec,
				deleteCustomFileDebtsPec,
				resetExpireTimer,
				generateFileByType,
				getDebtsSummary,
				setPayedAndDatePayedDebt,
				logs
			}}
			{...props}
		/>
	)
}
const usePracticesDebts = () => React.useContext(PracticesDebtsContext)

export { PracticesDebtsProvider, usePracticesDebts }