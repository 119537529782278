import React from 'react'

import { useAuth } from './auth-context';
import { httpPost, httpDelete, httpPatch, httpGet } from '../http'
import { USERS_ENDPOINT } from '../constants/API_ENDPOINT'

const UserContext = React.createContext()

function UserProvider(props) {
	const { jwtToken } = useAuth();

	const getUsers = async (search = null, filter = null, limit = 12, offset = 0) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${USERS_ENDPOINT}	`, jwtToken, {
				filter: filter,
				search: search,
				limit: limit,
				offset: offset
			});

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getUsersPractice = async (filter = null) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${USERS_ENDPOINT}/practices`, jwtToken, { filter });

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getSingleUser = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${USERS_ENDPOINT}/${id}`, jwtToken);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const newUser = async (data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPost(`${USERS_ENDPOINT}`, jwtToken, data);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const editUser = async (id, data) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpPatch(`${USERS_ENDPOINT}`, jwtToken, data, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const deleteUser = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpDelete(`${USERS_ENDPOINT}`, jwtToken, id);
			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	const getCompsAgents = async (id) => {
		let obj = {
			data: null,
			error: null,
			meta: null,
			status: null
		};

		try {
			const result = await httpGet(`${USERS_ENDPOINT}/agent-info/${id}`, jwtToken, { });

			obj = {
				data: result.data,
				error: null,
				meta: null,
				status: result.status,
			};
		} catch (err) {
			obj = {
				data: null,
				error: err,
				meta: null,
				status: null,
			};
		}

		return obj;
	}

	return (
		<UserContext.Provider
			value={{
				getUsers,
				getSingleUser,
				newUser,
				editUser,
				deleteUser,
				getCompsAgents,
				getUsersPractice
			}}
			{...props}
		/>
	)
}
const useUser = () => React.useContext(UserContext)

export { UserProvider, useUser }