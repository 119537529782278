import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../../../custom-components';
import { InputNumber, InputDate, InputSelect, InputEmail } from '../../../../../forms';
import { useBank } from '../../../../../../contexts/banks-context';

const AddMutuo = ({ setOpenAddMutuo, handleSaveItems }) => {
	const { getBanksForPractice } = useBank();
	const [nContract, setNrContract] = useState(0);
	const [firstImport, setFirstImport] = useState(0);
	const [debit, setDebit] = useState(0);
	const [dateStart, setDateStart] = useState(null);
	const [dateClose, setDateClose] = useState(null);
	const [statusMutuo, setStatusMutuo] = useState('');
	const [haveDebit, setHaveDebit] = useState(false);
	const [banks, setBanks] = useState([]);
	const [bank, setBank] = useState(null);
	const [importLoan, setImportLoan] = useState(0);
	const [nInstallments, setNInstallments] = useState(0);
	const [singleInstallmentImport, setSingleInstallmentImport] = useState(0);
	const [tan, setTan] = useState(0);
	const [taeg, setTaeg] = useState(0);
	const [pecBank, setPecBank] = useState(null);

	useEffect(() => {
		const getBanks = async () => {
			const { data, error } = await getBanksForPractice();

			if (error !== null) return alert('Error to load banks');

			const dataToObj = data.map((item, index) => {
				return {
					index: index,
					pec: item.pec,
					value: item.name,
					label: item.name
				}
			});

			setBanks(dataToObj);
		}

		getBanks();
	}, [getBanksForPractice]);

	const handleOnChangeBank = async (value) => {
		const pec = banks.filter(item => item.label === value)[0].pec;
		setBank(value);
		setPecBank(pec);
	}

	const handleAddItem = async () => {
		const data = {
			nContract,
			bank,
			dateStart,
			dateClose,
			debit,
			firstImport,
			status: statusMutuo,
			haveDebit,
			importLoan,
			nInstallments,
			singleInstallmentImport,
			tan,
			taeg
		}

		await handleSaveItems(data);
		setOpenAddMutuo(false);
	}

	const status = [
		{ value: 'In corso', label: 'In corso' },
		{ value: 'Chiuso', label: 'Chiuso' }
	];

	const handleChangeStatus = (value) => {
		if (value === 'Chiuso') {
			setDateClose(moment().format('YYYY-MM-D'));
			setDateStart(undefined);
		} else {
			setDateClose(undefined);
			setDateStart(moment().format('YYYY-MM-D'));
		}
		setStatusMutuo(value);
	}

	const handleOnChangeDebit = async (value) => {
		setHaveDebit(value === 'SI' ? false : true);
	}

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo Mutuo</h3>
					<button className="close-button" onClick={() => setOpenAddMutuo(false)}>&times;</button>
				</div>

				<hr />

				<div className="row">
					<div className="col-md-3">
						<InputSelect data={status} label="Stato" name="status" onChange={handleChangeStatus} />
					</div>
					<div className="col-md-3">
						<InputNumber type="number" label="Nr. Contratto" name="nr-contratto" step="1" onChange={setNrContract} />
					</div>

					<div className="col-md-3">
						<InputSelect defaultValue={bank} name="bank" data={banks} label="Finanziaria/Banca" isRequired={false} onChange={handleOnChangeBank} />
					</div>

					{
						statusMutuo !== 'Chiuso' ?
							<div className="col-md-3">
								<InputDate label="Data Stipula" defaultValue={dateStart} name="stipulationDate" onChange={setDateStart} />
							</div>
							:
							<div className="col-md-3">
								<InputDate label="Data Chiusura" defaultValue={dateClose} name="closedDate" onChange={setDateClose} />
							</div>
					}
				</div>

				<div className="row mt-2">
					<div className="col-md-3">
						<InputNumber price={true} validation={null} type="number" defaultValue={firstImport} label="Importo Iniziale" name="firstImport" step="0.01" onChange={setFirstImport} />
					</div>

					<div className="col-md-3">
						<InputNumber price={true} validation={null} type="number" defaultValue={debit} label="Debito Residuo" name="debit" step="0.01" onChange={setDebit} />
					</div>

					<div className="col-md-3">
						<InputEmail defaultValue={pecBank} name="pecBank" label="PEC Banca" onChange={setPecBank} />
					</div>

					<div className="col-md-3">
						<InputNumber valdiation={null} type="number" step="0.01" isRequired={false} price={true} name="importLoan" label="Importo Prestito" onChange={setImportLoan} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-3">
						<InputNumber valdiation={null} type="number" step="1" isRequired={false} name="nInstallments" label="Nr. Rate" onChange={setNInstallments} />
					</div>
					<div className="col-md-3">
						<InputNumber valdiation={null} type="number" step="0.01" isRequired={false} price={true} name="singleInstallmentImport" label="Importo Singola Rata" onChange={setSingleInstallmentImport} />
					</div>
					<div className="col-md-3">
						<InputNumber valdiation={null} type="number" step="0.01" isRequired={false} perc={true} name="tan" label="TAN" onChange={setTan} />
					</div>
					<div className="col-md-3">
						<InputNumber valdiation={null} type="number" step="0.01" isRequired={false} perc={true} name="taeg" label="TAEG" onChange={setTaeg} />
					</div>
				</div>

				<div className="row mt-2">
					<div className="col-md-4">
						<InputSelect defaultValue={''} name="haveDebit" data={[{ value: 'SI', label: 'SI' }, { value: 'NO', label: 'NO' }]} label="Il Cliente sta riuscendo a pagare?" isRequired={false} onChange={handleOnChangeDebit} />
					</div>
				</div>

				<hr />

				<input onClick={handleAddItem} type="button" value="Aggiungi" className="btn btn-primary" />

			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

const Mutui = ({ disabled = false, mutuiItems = [], haveMutui = false, setHaveMutui = () => { }, setMutuiItems = () => { }, handleGetTotalDebit = () => { } }) => {
	const [openAddMutuo, setOpenAddMutuo] = useState(false);
	const [viewedItems, setViewdItems] = useState(null);

	const handleSaveItems = async (data) => {
		setMutuiItems([...mutuiItems, data]);
		handleGetTotalDebit();
	}

	useEffect(() => {
		const handleRemove = (item) => {
			const index = mutuiItems.indexOf(item);
			setMutuiItems(items => items.filter((_val, i) => i !== index));
		}

		let data = [];

		mutuiItems.map((item, i) => {
			data.push(
				<tr key={i}>
					<td>{item.status}</td>
					<td>{item.nContract}</td>
					<td>{item.bank}</td>
					<td>{item.dateStart !== undefined ? moment(item.dateStart).format('DD/MM/YYYY') : 'n/a'}</td>
					<td>{item.dateClose !== undefined ? moment(item.dateClose).format('DD/MM/YYYY') : 'n/a'}</td>
					<td>&euro; {parseFloat(item.firstImport).toFixed(2)}</td>
					<td>&euro; {parseFloat(item.debit).toFixed(2)}</td>
					<td><button disabled={disabled} className="close-button d-block ms-auto" type="button" onClick={() => handleRemove(item)}>Rimuovi</button></td>
				</tr>
			)

			return {}
		})

		handleGetTotalDebit();
		setViewdItems(data);
	}, [mutuiItems, disabled, setMutuiItems, handleGetTotalDebit]);

	return (
		<>
			<div className="d-flex flex-columns align-items-center">
				<label htmlFor="Mutui" className="mb-2"><input disabled={disabled} onChange={() => setHaveMutui(!haveMutui)} type="checkbox" defaultChecked={haveMutui} name="Mutui" id="Mutui" /> <span className="mx-2"> Mutui?</span></label>
				{haveMutui && mutuiItems.length < 3 && !disabled ? <button onClick={() => setOpenAddMutuo(!openAddMutuo)} type="button" className="btn btn-primary ms-auto">Aggiungi Mutui</button> : null}
			</div>

			{ openAddMutuo ? <AddMutuo setOpenAddMutuo={setOpenAddMutuo} handleSaveItems={handleSaveItems} /> : null}

			{ mutuiItems.length > 0 ?
				<div className="mutui-items">
					<hr />

					<table className="table">
						<thead>
							<tr>
								<th>Stato</th>
								<th>Nr. Contratto</th>
								<th>Finanziaria</th>
								<th>Data Stipula</th>
								<th>Data Chiusura</th>
								<th>Importo Iniziale</th>
								<th>Debito</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								viewedItems
							}
						</tbody>
					</table>
				</div>
				: null}
		</>
	)
}

export default Mutui;