import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ListUsers from '../components/users/list-users';
import EditUser from '../components/users/edit-user';

import { PracticesCqsProvider } from '../contexts/practices-cqs-context';
import { PracticesLoansProvider } from '../contexts/practices-loans-context';
import { PracticesDebtsProvider } from '../contexts/practices-debts-context';
import { UserProvider } from '../contexts/user-context';

const Users = ({ match }) => {
	return (
		<div className="users-page">
			<UserProvider>
				<PracticesCqsProvider>
					<PracticesLoansProvider>
						<PracticesDebtsProvider>
							<Switch>
								<Route exact path={`${match.path}`} component={ListUsers} />
								<Route exact path={`${match.path}/:id`} component={EditUser} />
							</Switch>
						</PracticesDebtsProvider>
					</PracticesLoansProvider>
				</PracticesCqsProvider>
			</UserProvider>
		</div>
	)
}

export default Users;