import React from 'react';

import { useCustomer } from '../../../contexts/customer-context';
import { NewPageWrapper, NewPageWrapperCopy } from '../../../ui-components/custom-components';
import FormCustomer from '../../../ui-components/forms-components/customer';

const NewCustomer = ({ setShowNewCustomer, setCustomers }) => {
	const { newCustomer, getCustomers } = useCustomer();

	const onSubmit = async (form) => {
		const { error } = await newCustomer(form);

		if (error !== null) return alert(error.response.data.description || 'Errore');

		const { data } = await getCustomers();
		setCustomers(data.data);
		setShowNewCustomer(false);
	}

	return (
		<NewPageWrapper className="new-customer-page new-page">
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo Cliente</h3>
					<button className="close-button" onClick={() => setShowNewCustomer(false)}>&times;</button>
				</div>

				<hr />

				<FormCustomer handleSave={onSubmit} />
			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

export default NewCustomer;