export const downloadFile = async (id, type, fun) => {
	const { data, error } = await fun(id, type);
	
	if (error !== null) {
		return alert(error.response.data.error.msg || 'Errore sconosciuto');
	}

	const buffer = new Uint8Array(data.buffer.Body.data);
	const filename = data.filename;

	const blob = new Blob([buffer], {
		type: data.buffer.ContentType
	});

	const url = window.URL.createObjectURL(blob);
	const element = document.createElement('a');

	element.href = url;
	element.download = filename.replace(`${id}-`, '');

	document.body.appendChild(element);

	element.click();
	element.remove();
}

export const deleteFile = async (id, type, fun) => {
	const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

	if (!confirmDelete) {
		return;
	}

	const { error } = await fun(id, type);

	if (error !== null) {
		return alert(error.response.data.error.msg || 'Errore sconosciuto');
	}

	window.location.reload();
}

export const uploadFile = async (e, id, fun, loadingFun) => {
	e.preventDefault();

	loadingFun("Caricamento file...");

	const type = e.target[0].value;
	const file = e.target[1].files[0];

	if (file.size >= 20971520) {
		loadingFun("Carica");
		return alert('Il file supera i 20MB.');
	}

	const formData = new FormData();
	formData.append('file', file);

	const { error } = await fun(id, type, formData);
	if (error !== null) {
		loadingFun("Carica");
		return alert('Impossibile caricare il file.')
	}

	window.location.reload();
}

export const uploadCustomFile = async (e, id, fun, loadingFun) => {
	e.preventDefault();

	loadingFun("Caricamento file...");

	const filename = e.target[0].value;
	const file = e.target[1].files[0];

	if (file.size >= 20971520) {
		loadingFun("Carica");
		return alert('Il file supera i 20MB.');
	}

	const formData = new FormData()
	formData.append('file', file);

	const { error } = await fun(id, filename, formData);
	if (error !== null) {
		loadingFun("Carica");
		return alert(error.response.data.error.msg || 'Errore sconosciuto');
	}

	window.location.reload();
}

export const previewCustomFile = async (id, type, fun, download = true) => {
	const { data, error } = await fun(id, type);

	if (error !== null) {
		return alert(error.response.data.error.msg || 'Errore sconosciuto');
	}

	const buffer = new Uint8Array(data.buffer.Body.data);
	const filename = data.filename;

	const blob = new Blob([buffer], {
		type: data.buffer.ContentType
	});

	if (download) {
		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = String(filename).replace(`${id}-`, ``).replace(`-Custom`, ``).replace('-Pec', '');

		document.body.appendChild(element);

		element.click();
		element.remove();

		return;
	}

	const url = window.URL.createObjectURL(blob);
	window.open(url);
}

export const deleteCustomFile = async (id, type, fun) => {
	const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

	if (!confirmDelete) {
		return;
	}

	const { error } = await fun(id, type);

	if (error !== null) {
		return alert(error.response.data.error.msg || 'Errore sconosciuto');
	}

	window.location.reload();
}
