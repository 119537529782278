import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';

import { useAuth } from '../../../contexts/auth-context';
import { useCustomer } from '../../../contexts/customer-context';
import { useUser } from '../../../contexts/user-context';
import NewCustomer from '../new-customer';
import { getPagination } from '../../../utils/functions';

const ListCustomers = (props) => {
	const { role } = useAuth();
	const { getUsersPractice } = useUser();
	const { getCustomers, deleteCustomer, generateExcel } = useCustomer();
	const [customers, setCustomers] = useState(null);
	const [search, setSearch] = useState(null);
	const [filter, setFilter] = useState(null);
	const [user, setUser] = useState(null);
	const [users, setUsers] = useState([]);
	const [age, setAge] = useState(null);
	const [sex, setSex] = useState(null);
	const [typePerson, setTypePerson] = useState(null);
	const [study, setStudy] = useState(null);

	const limit = 50;
	const [offset, setOffset] = useState(0);
	const [pagination, setPagination] = useState(null);

	const [showNewCustomer, setShowNewCustomer] = useState(false);

	useEffect(() => {
		const initCustomers = async () => {
			const { data } = await getCustomers(search, filter === 'null' ? null : filter, user === 'null' ? null : user, age === 'null' ? null : age, sex === 'null' ? null : sex, typePerson === 'null' ? null : typePerson, study === 'null' ? null : study, limit, offset);
			setCustomers(data.data);
			getPagination(data.length, limit, setOffset, setPagination);
		}

		const getUsers = async () => {
			const { data } = await getUsersPractice();
			setUsers(data);
		}

		initCustomers();
		getUsers();
	}, [getCustomers, filter, limit, offset, search, user, age, sex, typePerson, study]);

	const handleDeleteCustomer = async (id) => {
		const confirmDelete = window.confirm('Sei sicuro di voler eliminare il cliente?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteCustomer(id);

		if (error !== null) {
			return console.log('Impossibile eliminare il cliente');
		}

		const { data } = await getCustomers(search, filter === 'null' ? null : filter, user === 'null' ? null : user, limit, offset);
		setCustomers(data.data);
		getPagination(data.length, limit, setOffset, setPagination);
	}

	const handleSearch = async () => {
		const { data } = await getCustomers(search, filter === 'null' ? null : filter, user === 'null' ? null : user, limit, offset);
		setCustomers(data.data);
		getPagination(data.length, limit, setOffset, setPagination);
	}

	const handleGenerateExcel = async () => {
		const { data, error } = await generateExcel(search, filter === 'null' ? null : filter, user === 'null' ? null : user);


		if (error !== null) {
			return alert(error.response.data.description || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.data);
		const filename = 'Clienti.xlsx';

		const blob = new Blob([buffer], {});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename;

		document.body.appendChild(element);

		element.click();
		element.remove();
	}

	let ages = [];

	for (let i = 1; i <= 120; i++) {
		ages.push(i)
	}

	return (
		<div className="list-customers-page">
			<div className="top-bar">
				<button className="btn btn-primary" onClick={() => setShowNewCustomer(!showNewCustomer)}>Nuovo cliente</button>

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setFilter(e.target.value)}>
					<option value="null">Seleziona un filtro</option>
					{role === 'AVVOCATO' ? <option value="DECRETI-INGIUNTIVI">Decreti Ingiuntivi</option> : null}
					<option value="CONTRATTUALIZZATI">Clienti Contrattualizzati</option>
					<option value="NO-CONTRATTUALIZZATI">Clienti NON Contrattualizzati</option>
				</select>

				{
					role === 'ADMIN' ?
						<select className="form-select d-inline w-auto mx-2" onChange={(e) => setUser(e.target.value)}>
							<option value="null">Filtra per utente</option>
							{
								users.map((item, index) => <option key={index} value={item._id}>{item.username}</option>)
							}
						</select>
						: null
				}

				<div className="wrapper-input-group w-auto d-inline-block">
					<div className="input-group">
						<input className="form-control" placeholder="Cerca un cliente" onChange={(e) => setSearch(e.target.value)} />
						<input className="btn btn-primary" type="submit" value="Cerca" onClick={handleSearch} />
					</div>
				</div>

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setAge(e.target.value)}>
					<option value="null">Seleziona un'età</option>
					{
						ages.map((item, index) => <option value={item} key={index}>{item}</option>)
					}
				</select>

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setSex(e.target.value)}>
					<option value="null">Seleziona un sesso</option>
					<option value="UOMO">UOMO</option>
					<option value="DONNA">DONNA</option>
				</select>

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setTypePerson(e.target.value)}>
					<option value="null">Seleziona un tipo di persona</option>
					<option value="PERSONA FISICA">PERSONA FISICA</option>
					<option value="PERSONA GIUDIDICA">PERSONA GIURIDICA</option>
				</select>

				<select className="form-select d-inline w-auto mx-2" onChange={(e) => setStudy(e.target.value)}>
					<option value="null">Seleziona un titolo di studio</option>
					<option value="DIPLOMA SCUOLA MEDIA">DIPLOMA SCUOLA MEDIA</option>
					<option value="DIPLOMA MATURITÀ">DIPLOMA MATURITÀ</option>
					<option value="LAUREA TRIENNALE">LAUREA TRIENNALE</option>
					<option value="LAUREA MAGISTRALE">LAUREA MAGISTRALE</option>
				</select>

				<button onClick={handleGenerateExcel} className="btn btn-primary mx-2">Genera XLSX</button>
			</div>

			<hr />

			<nav className="pagination-section">
				<ul className="pagination">
					<li className="page-item">
						<button className="page-link" onClick={() => { if (offset > 0) setOffset(offset - limit) }} aria-label="Previous">
							<span aria-hidden="true">&laquo;</span>
						</button>
					</li>
					{pagination}
					<li className="page-item">
						<button className="page-link" onClick={() => { setOffset(offset + limit) }} aria-label="Next">
							<span aria-hidden="true">&raquo;</span>
						</button>
					</li>
				</ul>
			</nav>

			<table className="table table-striped">
				<thead className="thead-dark">
					<tr>
						<th></th>
						<th>Cognome</th>
						<th>Nome</th>
						<th>Email</th>
						<th>Telefono</th>
						<th>Operatore</th>
						<th>Contrattualizzato</th>
						<th>Data Creazione</th>
					</tr>
				</thead>
				<tbody>
					{customers !== null ?
						customers.map((item, index) => {
							return (
								<tr key={index}>
									<td>
										<NavLink to={`${props.match.path}/${item._id}`} className="btn btn-secondary"><FontAwesomeIcon icon={faEdit} /></NavLink>
										<button type="button" onClick={() => handleDeleteCustomer(item._id)} className="mx-2 btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
									</td>
									<td className="text-uppercase">{item.last_name}</td>
									<td className="text-uppercase">{item.first_name}</td>
									<td>{item.email}</td>
									<td className="text-uppercase">{item.phone}</td>
									<td>{!!item.userdata[0]?item.userdata[0].username:"-"}</td>
									<td className="text-uppercase">{item.isContractual ? 'SI' : 'NO'}</td>
									<td className="text-uppercase">{moment(item.created_at).format('DD/MM/YYYY')}</td>
								</tr>
							)
						})
						: null}
				</tbody>
			</table>

			{showNewCustomer ? <NewCustomer setShowNewCustomer={setShowNewCustomer} setCustomers={setCustomers} /> : null}
		</div>
	)
}

export default ListCustomers;
