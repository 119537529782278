import styled from 'styled-components';

export const SidebarMain = styled.div`
	min-width: 300px;
	height: 100%;
	background: #15616d;
	padding: 25px;
	border-right: 1px solid #000;

	a {
		color: #fff;
		text-decoration: none;
		font-size: 22px;
		font-weight: 900;
		margin-bottom: 0!important;
		margin-top: 0!important;
	}

	hr {
		border-top: 1px solid #0002;
		z-index: 99999;
		width: 100%;
	}
`

export const SidebarLink = styled.span`
	display: block;
	border-radius: 4px;
	margin-bottom: 10px;
	padding: 5px;
	color: #eee;
	font-size: 16px;
	transition: .4s ease;
	
	&:hover {
		background: #105560;
		color: #fff;
		text-decoration: none
	}

	span {
		margin-left: 15px;
		font-weight: 600;
	}
`