import React, { useEffect, useState } from 'react';

import { useUser } from '../../../contexts/user-context';
import FormUser from '../../../ui-components/forms-components/user';

const EditUser = (props) => {
	const { id } = props.match.params;
	const { editUser, getSingleUser, getCompsAgents } = useUser();

	const [user, setUser] = useState(null);
	const [practicesUser, setPracticesUser] = useState(null);

	useEffect(() => {
		const getUser = async () => {
			const { data } = await getSingleUser(id);
			setUser(data);

			// if (data.comp === 'AGENTE') {
			const datauser = await getCompsAgents(id);
			setPracticesUser(datauser.data);
			// }
		}

		getUser();
	}, [getSingleUser, id, getCompsAgents])

	const onSubmit = async (data) => {
		const { error } = await editUser(id, data);

		if (error !== null) {
			console.log(error.response);
			return;
		}

		window.location.reload();
	}

	return (
		<div className="edit-user-page px-3 py-3">
			{
				user ?
					<>
						<div className="row">
							<div className="col-md-7">
								<div className="top-page">
									<h3 className="top-page-title">Modifica Utente</h3>
								</div>

								<hr />

								<FormUser user={user} practicesUser={practicesUser} handleSave={onSubmit} />
							</div>
						</div>
					</>
					: null}
		</div>
	)
}

export default EditUser;