import React from 'react';

import Dashboard from '../../ui-components/dashboard';

const AuthenticatedApp = () => {
	return (
		<Dashboard />
	)
}

export default AuthenticatedApp