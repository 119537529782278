import React, { useEffect, useState } from 'react';

import { NewPageWrapper, NewPageWrapperCopy } from '../../../../../custom-components';
import { InputNumber, InputText } from '../../../../../forms';

const AddStock = ({ setOpenAddStock, handleSaveItems }) => {
	const [type, setType] = useState('');
	const [value, setValue] = useState(0);

	const handleAddItem = async () => {
		const data = {
			type,
			value
		}

		await handleSaveItems(data);
		setOpenAddStock(false);
	}

	return (
		<NewPageWrapper>
			<NewPageWrapperCopy>
				<div className="top-page">
					<h3 className="top-page-title">Nuovo Titolo</h3>
					<button className="close-button" onClick={() => setOpenAddStock(false)}>&times;</button>
				</div>

				<hr />

				<div className="row">
					<div className="col-md-3">
						<InputText style={{ textTransform: 'uppercase' }} label="Tipologia" name="type" onChange={setType} />
					</div>
					<div className="col-md-3">
						<InputNumber price={true} validation={null} type="number" label="Valore Investimento" name="value" step="0.01" onChange={setValue} />
					</div>
				</div>

				<hr />

				<input onClick={handleAddItem} type="button" value="Aggiungi" className="btn btn-primary" />

			</NewPageWrapperCopy>
		</NewPageWrapper>
	)
}

const Stocks = ({ disabled = false, stockItems = [], haveStock = false, setHaveStock = () => {}, setStockItems = () => {}, handleGetTotalPatrimonial = () => {} }) => {
	const [openAddStock, setOpenAddStock] = useState(false);
	const [viewedItems, setViewdItems] = useState(null);

	const handleSaveItems = async (data) => {
		setStockItems([...stockItems, data]);
		handleGetTotalPatrimonial();
	}

	useEffect(() => {
		const handleRemove = (item) => {
			const index = stockItems.indexOf(item);
			setStockItems(items => items.filter((_val, i) => i !== index));
		}

		let data = [];

		stockItems.map((item, i) => {
			data.push(
				<tr key={i}>
					<td>{item.type}</td>
					<td>&euro; {parseFloat(item.value).toFixed(2)}</td>
					<td><button disabled={disabled} className="close-button d-block ms-auto" type="button" onClick={() => handleRemove(item)}>Rimuovi</button></td>
				</tr>
			)

			return {};
		})

		setViewdItems(data);
	}, [stockItems, disabled, setStockItems]);

	return (
		<>
			<div className="d-flex flex-columns align-items-center">
				<label htmlFor="stock" className="mb-2"><input disabled={disabled} onChange={() => setHaveStock(!haveStock)} type="checkbox" defaultChecked={haveStock} name="stock" id="stock" /> <span className="mx-2"> Titoli?</span></label>
				{haveStock && stockItems.length < 10 && !disabled ? <button onClick={() => setOpenAddStock(!openAddStock)} type="button" className="btn btn-primary ms-auto">Aggiungi Titolo</button> : null}
			</div>

			{ openAddStock ? <AddStock setOpenAddStock={setOpenAddStock} handleSaveItems={handleSaveItems} /> : null}

			{ stockItems.length > 0 ?
				<div className="stock-items">
					<hr />

					<table className="table">
						<thead>
							<tr>
								<th>Tipologia</th>
								<th>Valore Investimento</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{
								viewedItems
							}
						</tbody>
					</table>
				</div>
				: null}
		</>
	)
}

export default Stocks;