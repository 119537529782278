import React from 'react';
import { Switch, Route } from 'react-router-dom';

import EditPracticeCqs from '../components/practices-cqs/edit-practice-cqs';
import ListPracticesCqs from '../components/practices-cqs/list-practices-cqs';

import { PracticesCqsProvider } from '../contexts/practices-cqs-context';
import { CustomerProvider } from '../contexts/customer-context';
import { BanksProvider } from '../contexts/banks-context';
import { CommentsProvider } from '../contexts/comments-context';
import { ArchivesProvider } from '../contexts/archives-context';
import { UserProvider } from '../contexts/user-context';

const PracticesCqs = ({ match }) => {
	return (
		<div className="practices-page">
			<PracticesCqsProvider>
				<CustomerProvider>
					<CommentsProvider>
						<BanksProvider>
							<ArchivesProvider>
								<UserProvider>
									<Switch>
										<Route exact path={`${match.path}`} component={ListPracticesCqs} />
										<Route exact path={`${match.path}/:id`} component={EditPracticeCqs} />
									</Switch>
								</UserProvider>
							</ArchivesProvider>
						</BanksProvider>
					</CommentsProvider>
				</CustomerProvider>
			</PracticesCqsProvider>
		</div>
	)
}

export default PracticesCqs;