import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import itLocale from '@fullcalendar/core/locales/it';

import { useGoogle } from '../../contexts/google-context';
import moment from 'moment';

const UICalendar = () => {
	const { listEvents } = useGoogle();

	const [events, setEvents] = useState(null);

	const headerToolbar = {
		left: 'dayGridMonth,timeGridWeek,timeGridDay',
		center: 'title',
		right: 'prev,next',
	};

	useEffect(() => {
		const getEvents = async () => {
			const { data } = await listEvents();
			
			const _events = data.items.map((item) => {
				let allDay = moment(item.start.dateTime).toISOString() === moment(item.end.dateTime).toISOString()

				let startDate = allDay ? moment(item.start.date).toISOString() : moment(item.start.dateTime).toISOString();
				let endDate = allDay ? moment(item.end.date).toISOString() : moment(item.end.dateTime).toISOString();

				return {
					title: item.summary,
					start: startDate,	
					end: endDate,
					allDay: allDay
				}
			})

			setEvents(_events);
		}

		getEvents();
	}, [listEvents]);

	return (
		<FullCalendar
			plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
			initialView="timeGridWeek"
			headerToolbar={headerToolbar}
			events={events}
			slotMinTime={'06:00:00'}
			locale={itLocale}
		/>
	)
}

export default UICalendar;