import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload, faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';

import { useCustomer } from '../../../contexts/customer-context';
import { useAuth } from '../../../contexts/auth-context';
import FormCustomer from '../../../ui-components/forms-components/customer';

const UploadComponentCustomer = ({ role, defaultValue, isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<select value={defaultValue} className="form-select mb-2" name="typefile" id="typefile">
				{
					!isDisable ?
						<>
							{defaultValue === 'docid' ? <option value="docid">Documento D'identità</option> : null}
							{defaultValue === 'doccf' ? <option value="doccf">Codice Fiscale</option> : null}
							{defaultValue === 'docman' ? <option value="docman">Mandato</option> : null}
							{defaultValue === 'docpriv' ? <option value="docpriv">Privacy</option> : null}
							{
								role === 'ADMIN' ?
									<>
										{defaultValue === 'docproc' ? <option value="docproc">Procura</option> : null}
										{defaultValue === 'doc119' ? <option value="doc119">119</option> : null}
									</>
									: null
							}
						</>
						: null}
				{defaultValue === 'docDecree' ? <option value="docDecree">Decreto Ingiuntivo</option> : null}
			</select>

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}

const UploadCustomComponentCustomer = ({ isDisable = false, handleUpload, textBtn }) => {
	return (
		<form onSubmit={handleUpload}>
			<label htmlFor="filename">Nome File</label>
			<input type="text" className="form-control mb-2" name="filename" id="filename" />

			<input type="file" className="form-control mb-2" name="file" id="file" />
			<input type="submit" value={textBtn} className="btn btn-primary" />

			<hr />
		</form>
	)
}

const EditCustomer = (props) => {
	const { id } = props.match.params;
	const { role } = useAuth();
	const { uploadCustomFileCustomer, downloadCustomFileCustomer, deleteCustomFileCustomer, editCustomer, getSingleCustomer, uploadFileCustomer, downloadFileCustomer, deleteFileCustomer } = useCustomer();

	const componentRef = useRef()
	const [customer, setCustomer] = useState(null);
	const [showUpload, setShowUpload] = useState(false);
	const [showCustomUpload, setShowCustomUpload] = useState(false);
	const [defaultUploadValue, setDefaultUploadValue] = useState(null);
	const [isDisable, setIsDisable] = useState(false);
	const [textBtn, setTextBtn] = useState("Carica");

	useEffect(() => {
		const getCustomer = async () => {
			const { data, error } = await getSingleCustomer(id);

			if (error !== null) {
				return window.location.href = '/customers';
			}

			setCustomer(data);
			setIsDisable(data.isDisable);
		}

		getCustomer();
	}, [getSingleCustomer, id])

	const onSubmit = async (data) => {
		const { error } = await editCustomer(id, data);

		if (error !== null) return alert(`Copiare il contenuto e inviarlo a Masamune per risolvere al più presto.\n\n\nContenuto Da Copiare:\n\n\n ${JSON.stringify(error.response)}`);

		window.location.reload();
	}

	const uploadFile = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const type = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 20971520) {
			setTextBtn("Carica");
			return alert('Il file supera i 20MB.');
		}

		const formData = new FormData();
		formData.append('file', file);

		const { error } = await uploadFileCustomer(id, type, formData);
		if (error !== null) {
			setTextBtn("Carica");
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		window.location.reload();
	}

	const downloadFile = async (type) => {
		const { data, error } = await downloadFileCustomer(id, type);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.Body.data);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: data.buffer.ContentType
		});

		const url = window.URL.createObjectURL(blob);
		const element = document.createElement('a');

		element.href = url;
		element.download = filename.replace(`${id}-`, '');

		document.body.appendChild(element);

		element.click();
		element.remove();
	}

	const deleteFile = async (type) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteFileCustomer(id, type);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		window.location.reload();
	}

	const uploadCustomFile = async (e) => {
		e.preventDefault();

		setTextBtn("Caricamento file...");

		const filename = e.target[0].value;
		const file = e.target[1].files[0];

		if (file.size >= 20971520) {
			setTextBtn("Carica");
			return alert('Il file supera i 20MB.');
		}

		const formData = new FormData()
		formData.append('file', file);

		const { error } = await uploadCustomFileCustomer(id, filename, formData);
		if (error !== null) {
			setTextBtn("Carica");
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		window.location.reload();
	}

	const previewCustomFile = async (type, download = true) => {
		const { data, error } = await downloadCustomFileCustomer(id, type);

		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}

		const buffer = new Uint8Array(data.buffer.Body.data);
		const filename = data.filename;

		const blob = new Blob([buffer], {
			type: data.buffer.ContentType
		});

		if (download) {
			const url = window.URL.createObjectURL(blob);
			const element = document.createElement('a');

			element.href = url;
			element.download = String(filename).replace(`${id}-`, ``).replace(`-Custom`, ``);

			document.body.appendChild(element);

			element.click();
			element.remove();

			return;
		}

		const url = window.URL.createObjectURL(blob);
		window.open(url);
	}

	const deleteCustomFile = async (type) => {
		const confirmDelete = window.confirm('Sei sicuro di voler continuare?');

		if (!confirmDelete) {
			return;
		}

		const { error } = await deleteCustomFileCustomer(id, type);
		if (error !== null) {
			return alert(error.response.data.error.msg || 'Errore sconosciuto');
		}
		window.location.reload();
	}

	return (
		<div className="edit-customer-page px-3 py-3" ref={componentRef}>
			{
				customer ?
					<>
						<div className="row">
							<div className="col-md-8">
								<FormCustomer isDisable={isDisable} customer={customer} handleSave={onSubmit} />
							</div>

							<div className="col-md-4">
								<div className="top-page">
									<h3 className="top-page-title">Documenti Cliente</h3>
								</div>

								<hr />

								{
									showUpload ?
										<UploadComponentCustomer role={role} textBtn={textBtn} defaultValue={defaultUploadValue} isDisable={isDisable} handleUpload={uploadFile} />
										: null
								}
								{showCustomUpload ? <UploadCustomComponentCustomer textBtn={textBtn} isDisable={isDisable} handleUpload={uploadCustomFile} /> : null}


								<table className="table table-striped">
									<thead>
										<tr>
											<th>Tipo Documento</th>
											<th>Stato</th>
											<th>Azioni</th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>Documenti D'identità</td>
											<td>{customer.documents.docId === null ? 'n/a' : 'ok'}</td>
											<td>
												{customer.documents.docId === null ?
													<button onClick={() => { setShowUpload(true); setDefaultUploadValue('docid') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
													:
													<>
														&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('docid') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
														{!isDisable ?
															<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('docid') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
															: null}
													</>
												}
											</td>
										</tr>
										<tr>
											<td>Codice Fiscale</td>
											<td>{customer.documents.docCF === null ? 'n/a' : 'ok'}</td>
											<td>
												{customer.documents.docCF === null ?
													<button onClick={() => { setShowUpload(true); setDefaultUploadValue('doccf') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
													:
													<>
														&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('doccf') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
														{!isDisable ?
															<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('doccf') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
															: null}
													</>
												}
											</td>
										</tr>
										<tr>
											<td>Mandato</td>
											<td>{customer.documents.docMan === null ? 'n/a' : 'ok'}</td>
											<td>
												{customer.documents.docMan === null ?
													<button onClick={() => { setShowUpload(true); setDefaultUploadValue('docman') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
													:
													<>
														&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('docman') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
														{!isDisable ?
															<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('docman') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
															: null}
													</>
												}
											</td>
										</tr>
										<tr>
											<td>Privacy</td>
											<td>{customer.documents.docPriv === null ? 'n/a' : 'ok'}</td>
											<td>
												{customer.documents.docPriv === null ?
													<button onClick={() => { setShowUpload(true); setDefaultUploadValue('docpriv') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
													:
													<>
														&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('docpriv') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
														{!isDisable ?
															<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('docpriv') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
															: null}
													</>
												}
											</td>
										</tr>
										{
											role === 'ADMIN' ?
												<>
													<tr>
														<td>Procura</td>
														<td>{customer.documents.docProc === null ? 'n/a' : 'ok'}</td>
														<td>
															{customer.documents.docProc === null ?
																<button onClick={() => { setShowUpload(true); setDefaultUploadValue('docproc') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
																:
																<>
																	&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('docproc') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																	{!isDisable ?
																		<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('docproc') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
																		: null}
																</>
															}
														</td>
													</tr>
													<tr>
														<td>119</td>
														<td>{customer.documents.doc119 === null ? 'n/a' : 'ok'}</td>
														<td>
															{customer.documents.doc119 === null ?
																<button onClick={() => { setShowUpload(true); setDefaultUploadValue('doc119') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
																:
																<>
																	&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('doc119') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																	{!isDisable ?
																		<>&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('doc119') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button></>
																		: null}
																</>
															}
														</td>
													</tr>
												</>
												: null}
										{
											customer.injuctionDecree ?
												<tr>
													<td>Decreto Ingiuntivo</td>
													<td>{customer.documents.docDecree === null ? 'n/a' : 'ok'}</td>
													<td>
														{customer.documents.docDecree === null ?
															<button onClick={() => { setShowUpload(true); setDefaultUploadValue('docDecree') }} className="btn btn-secondary"><FontAwesomeIcon icon={faUpload} /></button>
															:
															<>
																&nbsp;<button onClick={(e) => { e.preventDefault(); downloadFile('docDecree') }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
															&nbsp;<button onClick={(e) => { e.preventDefault(); deleteFile('docDecree') }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
															</>
														}
													</td>
												</tr>
												: null}
									</tbody>
								</table>

								{
									customer.othersDocuments !== null && customer.othersDocuments !== undefined && customer.othersDocuments.length > 0 && role === 'ADMIN' ?
										<>
											<div className="top-page mt-4">
												<h3 className="top-page-title">Documenti Personalizzati</h3>
											</div>

											<hr />

											<table className="table table-striped">
												<thead>
													<tr>
														<th>Nome Documento</th>
														<th>Azioni</th>
													</tr>
												</thead>
												<tbody>
													{
														customer.othersDocuments.map((item, index) => {
															return (
																<tr key={index}>
																	<td>{String(item).replace(`${id}-`, ``).replace(`-Custom`, ``)}</td>
																	<td>
																		<button onClick={(e) => { e.preventDefault(); previewCustomFile(item, true) }} className="btn btn-success"><FontAwesomeIcon icon={faDownload} /></button>
																		<button onClick={(e) => { e.preventDefault(); deleteCustomFile(item) }} className="btn btn-danger"><FontAwesomeIcon icon={faTrash} /></button>
																	</td>
																</tr>
															)
														})
													}
												</tbody>
											</table>
										</>
										: null
								}

								{role === 'ADMIN' ?
									<button className="btn btn-primary mb-3" onClick={() => setShowCustomUpload(!showCustomUpload)}>Aggiungi File Personalizzato</button>
									: null
								}

							</div>
						</div>
					</>
					: null}
		</div>
	)
}

export default EditCustomer;