import React, { useEffect, useState } from 'react';

import MonthlyIncome from './monthly-income-section';
import { InputNumber, InputText, InputTextArea } from '../../../../forms';
import { BottomPreview } from '../../../../custom-components';

const OthersIncomeYearly = ({ dataToSubmit = {}, setDataToSubmit = () => {}, customer = null, isDisable = false }) => {
	const [haveMonthlyIncome, setHaveMonthlyIncome] = useState(customer !== null ? customer.monthlyIncome.haveMonthlyIncome : false);
	const [monthlyIncomeItems, setMonthlyIncomeItems] = useState(customer !== null ? customer.monthlyIncome.items : []);
	const [thirdForeclosureBeneficiary, setThirdForeclosureBeneficiary] = useState(customer !== null ? customer.thirdForeclosureBeneficiary : '');
	const [thirdForeclosureImport, setThirdForeclosureImport] = useState(customer !== null ? customer.thirdForeclosureImport : 0);
	const [spouseForeclosureBeneficiary, setSpouseForeclosureBeneficiary] = useState(customer !== null ? customer.spouseForeclosureBeneficiary : '');
	const [spouseForeclosureImport, setSpouseForeclosureImport] = useState(customer !== null ? customer.spouseForeclosureImport : 0);
	const [monthlySavingsIntermediary, setMonthlySavingsIntermediary] = useState(customer !== null ? customer.monthlySavingsIntermediary : '');
	const [monthlySavingsImport, setMonthlySavingsImport] = useState(customer !== null ? customer.monthlySavingsImport : 0);
	const [othersYearlyIncome, setOthersYearlyIncome] = useState(customer !== null ? customer.othersYearlyIncome : 0);
	const [othersYearlyIncomeNote, setOthersYearlyIncomeNote] = useState(customer !== null ? customer.othersYearlyIncomeNote : '');

	const [haveThirdForeclouse, setHaveThirdForeclouse] = useState(thirdForeclosureImport > 0 && thirdForeclosureBeneficiary !=='');
	const [haveSpouseForeclouse, setHaveSpouseForeclouse] = useState(spouseForeclosureImport > 0 && spouseForeclosureBeneficiary !=='');
	const [haveMonthlySavings, setHaveMonthlySavings] = useState(monthlySavingsImport > 0 && monthlySavingsIntermediary !=='');
	const [haveOthersYearlyIncome, setHaveOthersYearlyIncome] = useState(othersYearlyIncome > 0 && othersYearlyIncomeNote !=='');

	useEffect(() => {
		const data = {
			...dataToSubmit,
			monthlyIncome: {
				haveMonthlyIncome: haveMonthlyIncome,
				items: monthlyIncomeItems
			},
			thirdForeclosureBeneficiary: thirdForeclosureBeneficiary,
			thirdForeclosureImport: thirdForeclosureImport,
			spouseForeclosureBeneficiary: spouseForeclosureBeneficiary,
			spouseForeclosureImport: spouseForeclosureImport,
			monthlySavingsIntermediary: monthlySavingsIntermediary,
			monthlySavingsImport: monthlySavingsImport,
			othersYearlyIncome: othersYearlyIncome,
			othersYearlyIncomeNote: othersYearlyIncomeNote
		};
		setDataToSubmit(data);

		// eslint-disable-next-line
	}, [haveMonthlyIncome, monthlyIncomeItems, thirdForeclosureBeneficiary, thirdForeclosureImport, spouseForeclosureBeneficiary, spouseForeclosureImport, monthlySavingsIntermediary, monthlySavingsImport, othersYearlyIncome, othersYearlyIncomeNote]);

	const handleGetTotalOthersIncomes = () => {
		var total = 0;

		if (haveThirdForeclouse){
			total += parseFloat(thirdForeclosureImport * 12);
		}
		if (haveSpouseForeclouse){
			total += parseFloat(spouseForeclosureImport * 12);
		}
		if (haveMonthlySavings){
			total += parseFloat(monthlySavingsImport * 12);
		}
		if (haveOthersYearlyIncome){
			total += parseFloat(othersYearlyIncome * 12);
		}
		if (haveMonthlyIncome){
			for (let i = 0; i < monthlyIncomeItems.length; i++) {
				total += parseFloat(monthlyIncomeItems[i].value * 12)
			}
		}

		return total;
	}

	return (
		<div className="step-others-income-yearly">
			<div className="top-page">
				<h3 className="top-page-title">Altri Impegni Annui</h3>
			</div>

			<hr />

			<MonthlyIncome
				disabled={isDisable}
				setHaveMonthlyIncome={setHaveMonthlyIncome}
				setMonthlyIncomeItems={setMonthlyIncomeItems}
				haveMonthlyIncome={haveMonthlyIncome}
				monthlyIncomeItems={monthlyIncomeItems}
				handleGetTotalOthersIncomes={handleGetTotalOthersIncomes} />

			<hr />

			<div className="haveThirdForeclouse">
				<label htmlFor="haveThirdForeclouse" className="mb-2"><input disabled={isDisable} onChange={() => setHaveThirdForeclouse(!haveThirdForeclouse)} type="checkbox" defaultChecked={haveThirdForeclouse} name="haveThirdForeclouse" id="haveThirdForeclouse" /> <span className="mx-2"> Pignoramenti Terzi?</span></label>
				<br />
				{haveThirdForeclouse ?
					<div className="row">
						<div className="col-md-6">
							<InputText style={{ textTransform: 'uppercase' }} defaultValue={thirdForeclosureBeneficiary} label="Beneficiario del pignoramento" name="thirdForeclosureBeneficiary" onChange={setThirdForeclosureBeneficiary} />
						</div>
						<div className="col-md-6">
							<InputNumber price={true} defaultValue={thirdForeclosureImport} label="Importo" name="" type="number" validation={null} step="0.01" onChange={setThirdForeclosureImport} />
							<BottomPreview>Anno: &euro; {parseFloat(thirdForeclosureImport * 12).toFixed(2)}</BottomPreview>
						</div>
					</div>
					: null}
			</div>

			<hr />

			<div className="haveSpouseForeclouse">
				<label htmlFor="haveSpouseForeclouse" className="mb-2"><input disabled={isDisable} onChange={() => setHaveSpouseForeclouse(!haveSpouseForeclouse)} type="checkbox" defaultChecked={haveSpouseForeclouse} name="haveSpouseForeclouse" id="haveSpouseForeclouse" /> <span className="mx-2"> Pignoramenti Coniuge e/o Figli?</span></label>
				<br />
				{haveSpouseForeclouse ?
					<div className="row">
						<div className="col-md-6">
							<InputText style={{ textTransform: 'uppercase' }} defaultValue={spouseForeclosureBeneficiary} label="Beneficiario del pignoramento" name="spouseForeclosureBeneficiary" onChange={setSpouseForeclosureBeneficiary} />
						</div>
						<div className="col-md-6">
							<InputNumber price={true} defaultValue={spouseForeclosureImport} label="Importo" name="spouseForeclosureImport" type="number" validation={null} step="0.01" onChange={setSpouseForeclosureImport} />
							<BottomPreview>Anno: &euro; {parseFloat(spouseForeclosureImport * 12).toFixed(2)}</BottomPreview>
						</div>
					</div>
					: null}
			</div>

			<hr />

			<div className="haveMonthlySavings">
				<label htmlFor="haveMonthlySavings" className="mb-2"><input disabled={isDisable} onChange={() => setHaveMonthlySavings(!haveMonthlySavings)} type="checkbox" defaultChecked={haveMonthlySavings} name="haveMonthlySavings" id="haveMonthlySavings" /> <span className="mx-2"> Risparmio Mensile?</span></label>
				<br />
				{haveMonthlySavings ?
					<div className="row">
						<div className="col-md-6">
							<InputText style={{ textTransform: 'uppercase' }} defaultValue={monthlySavingsIntermediary} label="Intermediario del Risparmio" name="monthlySavingsIntermediary" onChange={setMonthlySavingsIntermediary} />
						</div>
						<div className="col-md-6">
							<InputNumber price={true} defaultValue={monthlySavingsImport} label="Importo" name="monthlySavingsImport" type="number" validation={null} step="0.01" onChange={setMonthlySavingsImport} />
							<BottomPreview>Anno: &euro; {parseFloat(monthlySavingsImport * 12).toFixed(2)}</BottomPreview>
						</div>
					</div>
					: null}
			</div>

			<hr />

			<div className="haveOthersYearlyIncome">
				<label htmlFor="haveOthersYearlyIncome" className="mb-2"><input disabled={isDisable} onChange={() => setHaveOthersYearlyIncome(!haveOthersYearlyIncome)} type="checkbox" defaultChecked={haveOthersYearlyIncome} name="haveOthersYearlyIncome" id="haveOthersYearlyIncome" /> <span className="mx-2"> Altro?</span></label>
				<br />
				{haveOthersYearlyIncome ?
					<div className="row">
						<div className="col-md-3">
							<InputNumber price={true} defaultValue={othersYearlyIncome} label="Importo" name="othersYearlyIncome" type="number" validation={null} step="0.01" onChange={setOthersYearlyIncome} />
							<BottomPreview>Anno: &euro; {parseFloat(othersYearlyIncome * 12).toFixed(2)}</BottomPreview>
						</div>
						<div className="col-md-12">
							<InputTextArea style={{ textTransform: 'uppercase' }} defaultValue={othersYearlyIncomeNote} label="Note" name="othersYearlyIncomeNote" onChange={setOthersYearlyIncomeNote} />
						</div>
					</div>
					: null}
			</div>

			<hr/>

			<p style={{ fontSize: 18, fontWeight: 'bold' }}>Totale Altri Impegni Annui: &euro; {parseFloat(handleGetTotalOthersIncomes()).toFixed(2)}</p>
		</div>
	)
}

export default OthersIncomeYearly;